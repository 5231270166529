/** @jsxImportSource theme-ui */
import { Box, Button, Container, Flex, jsx } from 'theme-ui'
import { Document } from '@contentful/rich-text-types'
import React from 'react'
import { ContentfulRichText } from './Contentful-Rich-Text'
import { Styled } from '~/components/styled'
import { ContentfulImage } from '~/features/image/contentful-image'

type Props = {
  bottomButtonLink?: string
  bottomButtonText?: string
  features?: {
    id: string
    titleColor?: {
      colorArray?: string[]
    }
    title?: string
    description?: {
      json?: Document
    }
    icon?: {
      url: string
      alt: string
    }
  }[]
}

const Item = React.memo(function Item(
  props: Required<Required<Props>['features']>[0] & { isLast: boolean }
) {
  const { icon, title, titleColor, description, isLast } = props
  return (
    <Box sx={{ mb: isLast ? null : [4, 7] }}>
      <Flex sx={{ display: ['block', 'flex'] }}>
        <Box sx={{ flex: [null, 1], display: ['block'] }}>
          <Box
            sx={{
              margin: ['auto'],
              maxWidth: ['30%', '80%'],
              px: [0, 3],
              marginY: [4, 0],
            }}
          >
            {icon && (
              <ContentfulImage
                {...icon}
                width={300}
                height={300}
                src={icon?.url}
                sx={{ display: 'block', height: 'auto' }}
                alt={icon?.alt || title || "Icon" }
                sizes="300px"
              />
            )}
          </Box>
        </Box>
        <Box sx={{ flex: [null, 2], ml: [0, 5] }}>
          {title && (
            <Styled.h2
              sx={{
                color: titleColor?.colorArray,
                fontWeight: '600',
                fontSize: [5, 6],
              }}
            >
              {title}
            </Styled.h2>
          )}
          {description?.json && <ContentfulRichText json={description.json} />}
        </Box>
      </Flex>
    </Box>
  )
})

export const IconFeatures = React.memo(function ComponentName(props: Props) {
  const { bottomButtonLink, bottomButtonText, features } = props

  return (
    <Box
      sx={{
        pb: [7],
        backgroundImage: (theme) =>
          `linear-gradient(${theme.colors?.background}, ${theme.colors?.mutedSecondary})`,
        textAlign: ['center', 'left'],
      }}
    >
      <Container sx={{ maxWidth: '900px', px: 3 }}>
        {features?.map((item, i) => {
          return <Item {...item} key={item.id} isLast={i === features.length} />
        })}
        {bottomButtonLink && bottomButtonText && (
          <Flex sx={{ justifyContent: 'center', mt: 4 }}>
            <a
              href={bottomButtonLink}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Button variant="pillOutline">{bottomButtonText}</Button>
            </a>
          </Flex>
        )}
      </Container>
    </Box>
  )
})
