import { Document } from '@contentful/rich-text-types'
import { ComponentProps } from 'react'
import { ContentfulQuestion } from '~/features/contentful-sections/quiz/results'
import ImageWithTextOverlay from './ImageWithTextOverlay'

type ContentfulSectionCreator<ID extends string> = {
  sys: { id: string }
  __typename: ID
}

type ContentfulSplitHero = ContentfulSectionCreator<'SplitHeroImage'> & {
  title?: string
  titleTag?: string
  herosubtitle?: string
  imageIsOnRight?: boolean
  contentBackgroundColor?: { colorArray: string[] }
  contentColor?: { colorArray: string[] }
  maxHeight?: string[]
  minHeight?: string[]
  image?: { url: string; height: number; width: number }
}

type ContentfulContactForm = ContentfulSectionCreator<'ContactForm'> & {
  title?: string
  successMessage?: string
  successTitle?: string
}

type ContentfulEmbedHTML = ContentfulSectionCreator<'EmbedHtml'> & {
  html?: string
  title?: string
}

type ContentfulDynamicProductDescription =
  ContentfulSectionCreator<'DynamicProductDescription'> & {
    title?: string
    descriptions?: {
      descriptions?: {
        skus?: string[]
        description?: string
        disclaimer?: string
      }[]
    }
  }

type ContentfulProductVideoReview =
  ContentfulSectionCreator<'ProductVideoReview'> & {
    title?: string
    videoReviewLinks?: string[]
  }

type ContentfulDynamicImageWithTextOverlay =
  ContentfulSectionCreator<'DynamicImageWithTextOverlay'> & {
    titleLineHeight?: string[]
    maxHeight?: string[]
    contentPadding?: string[]
    id: string
    underlineButton: boolean
    title?: string
    textOptionsCollection?: {
      items?: {
        skus?: string[]

        description?: {
          json?: { content?: { content?: { value?: string }[] }[] }
        }
        preTitle?: string
        title?: string
      }[]
    }
    defaultTextOption?: {
      description?: {
        json?: { content?: { content?: { value?: string }[] }[] }
      }
      preTitle?: string
      title?: string
    }
    image: {
      url: string
      height: number
      width: number
    }
    horizontalPosition: ComponentProps<
      typeof ImageWithTextOverlay
    >['horizontalPosition']
    verticalPosition: ComponentProps<
      typeof ImageWithTextOverlay
    >['verticalPosition']
    textAlign: ComponentProps<typeof ImageWithTextOverlay>['textAlign']
    minimumImageHeight: string
    textColor: string
    contentColor?: { colorArray: string[] }
    contentMaxWidth: string
    titleSize: ComponentProps<typeof ImageWithTextOverlay>['titleSize']
    imageFocusPosition: ComponentProps<
      typeof ImageWithTextOverlay
    >['imageFocusPosition']
    minHeight?: string[]
  }

  
type ContentfulSubscribeSection = ContentfulSectionCreator<'SubscribeSection'> & {
  subscribeText: string
  buttonText?: string | undefined
  backgroundColor:  { colorArray: string[] }
  textColor:  { colorArray: string[] }
  successText?: string | undefined
}

type ContentfulTwoButtonTextSection = ContentfulSectionCreator<'TwoButtonTextSection'> & {
  title?: string | undefined
  description?: Document | undefined
  leftText?: boolean | undefined
  firstButtonImage?: {
    url: string
    height: number
    width: number
  }
  firstButtonText?: string | undefined
  firstButtonUrl?: string | undefined
  secondButtonImage?: {
    url: string
    height: number
    width: number
  }
  secondButtonText?: string | undefined
  secondButtonUrl?: string | undefined
}

type ContentfulContentSection = ContentfulSectionCreator<'ContentSection'> & {
  title?: string | undefined
  description?: Document | undefined
  horizontal?: boolean | undefined
  itemsCollection?:{
    items?: {
      title?: string | undefined
      subTitle?: string | undefined
      description?: Document | undefined
      image: {
        url: string
        height: number
        width: number
      }
    }[]
  }
  showContentBorder?: boolean
  backgroundColor?: { colorArray: string[]}
  textColor?: { colorArray: string[]}
  borderColor?: { colorArray: string[]}
}

type ContentfulSMSSubscribeSection = ContentfulSectionCreator<'SmsSubscribeSection'> & {
  title?: string | undefined
  titleColor?: { colorArray: string[] }
  background?: {
    url: string
    height: number
    width: number
  }
  image?: {
    url: string
    height: number
    width: number
  }
}


export function isContentfulDynamicImageWithTextOverlay(
  section: ContentfulSection
): section is ContentfulDynamicImageWithTextOverlay {
  return !!(
    (section as ContentfulDynamicImageWithTextOverlay).__typename ===
    'DynamicImageWithTextOverlay'
  )
}

export function isContentfulEmbedHTML(
  section: ContentfulSection
): section is ContentfulEmbedHTML {
  return !!((section as ContentfulEmbedHTML).__typename === 'EmbedHtml')
}

export function isContentfulDynamicProductDescription(
  section: ContentfulSection
): section is ContentfulDynamicProductDescription {
  return !!(
    (section as ContentfulDynamicProductDescription).__typename ===
    'DynamicProductDescription'
  )
}

export function isContentfulProductVideoReview(
  section: ContentfulSection
): section is ContentfulProductVideoReview {
  return !!(
    (section as ContentfulProductVideoReview).__typename ===
    'ProductVideoReview'
  )
}

export function isContentfulContactForm(
  section: ContentfulSection
): section is ContentfulContactForm {
  return !!((section as ContentfulContactForm).__typename === 'ContactForm')
}
export function isContentfulSplitHero(
  section: ContentfulSection
): section is ContentfulSplitHero {
  return !!((section as ContentfulSplitHero).__typename === 'SplitHeroImage')
}


export function isContentfulSubscribeSection(
  section: ContentfulSection
): section is ContentfulSubscribeSection {
  return !!((section as ContentfulSubscribeSection).__typename === 'SubscribeSection')
}

export function isContentfulTwoButtonTextSection(
  section: ContentfulSection
): section is ContentfulTwoButtonTextSection {
  return !!((section as ContentfulTwoButtonTextSection).__typename === 'TwoButtonTextSection')
}

export function isContentfulContentSection(
  section: ContentfulSection
): section is ContentfulContentSection {
  return !!((section as ContentfulContentSection).__typename === 'ContentSection')
}


export function isContentfulSMSSubscribeSection(
  section: ContentfulSection
): section is ContentfulSMSSubscribeSection {
  return !!((section as ContentfulSMSSubscribeSection).__typename === 'SmsSubscribeSection')
}

// collection product grid
type ContentfulCollectionProductGrid =
  ContentfulSectionCreator<'CollectionProductGrid'> & {
    id: string
    backgroundColor?: {
      colorArray: string[]
    }
    showTitle?: boolean
    title?: string
    showReviewsCount: boolean
    imageHeightPercentage: string[]
    maxHeight: string[]
    showDescription?: boolean
    productsCollection: {
      items: {
        productHandle: string
        imagesCollection: {
          items: {
            url: string
            height: number
            width: number
          }[]
        }
      }[]
    }
    itemsPerRow?: number
    collectionHandle: string
    itemsPerRowTablet?: number
    itemsPerRowMobile?: number
    isLabReportsSection?: boolean
    labReportsCollection?: {
      items: Array<{
        productHandle: string
        imagesCollection: {
          items: Array<{
            url: string
            height: number
            width: number
          }>
        }
      }>
    }
  }

export function isContentfulCollectionProductGrid(
  section: ContentfulSection
): section is ContentfulCollectionProductGrid {
  return !!(
    (section as ContentfulCollectionProductGrid).__typename ===
    'CollectionProductGrid'
  )
}

// title section
type ContentfulDropdownLinkList = ContentfulSectionCreator<'DropdownLinkList'> & {
  id: string
  title?: string
  linksCollection: {
    items: {
      text: string
      url: string
      image: {
          url: string
          height: number
          width: number
        }
    }[]
  }
}

export function isDropdownLinkList(
  section: ContentfulSection
): section is ContentfulDropdownLinkList {
  return !!(
    (section as ContentfulDropdownLinkList).__typename ===
    'DropdownLinkList'
  )
}

// title section
type ContentfulTitleSection = ContentfulSectionCreator<'TitleSection'> & {
  preTitle: string
  headline: string
  headlineTag?: string
  id: string
  textAlign: string
  subtitle?: {
    json?: Document
  }
  fontWeight?: string[]
  headlineFontSize?: string[]
  backgroundColor?: {
    colorArray?: string[]
  }
}
export function isContentfulTitleSection(
  section: ContentfulSection
): section is ContentfulTitleSection {
  return !!((section as ContentfulTitleSection).__typename === 'TitleSection')
}

type ContentfulIconFeaturesSection =
  ContentfulSectionCreator<'IconFeatures'> & {
    bottomButtonLink?: string
    bottomButtonText?: string
    featuresCollection?: {
      items: {
        sys: { id: string }
        titleColor?: {
          colorArray?: string[]
        }
        title?: string
        description?: {
          json?: Document
        }
        icon?: {
          url: string
          height: number
          width: number
        }
      }[]
    }
  }

type ContentfulRoundedImage = ContentfulSectionCreator<'RoundedImage'> & {
  image?: {
    url: string
    height: number
    width: number
  }
  alt?: string
}

export function isContentfulRoundedImage(
  section: ContentfulSection
): section is ContentfulRoundedImage {
  return !!((section as ContentfulRoundedImage).__typename === 'RoundedImage')
}

export function isContentfulIconSection(
  section: ContentfulSection
): section is ContentfulIconFeaturesSection {
  return !!(
    (section as ContentfulIconFeaturesSection).__typename === 'IconFeatures'
  )
}

// image next to text
type ContentfulImageNextToText = ContentfulSectionCreator<'ImageNextToText'> & {
  productTitle?: string
  buttonText: string
  buttonUrl: string
  id: string
  underlineButton: string
  title: string
  titleTag?: string
  secondButtonText?: string
  secondButtonUrl?: string
  bulletPointsCollection?: {
    items: {
      text?: string
      icon?: {
        url: string
        width: number
        height: number
      }
    }[]
  }
  description?: {
    json: Document
    content?: { content?: { value?: string }[] }[]
  }
  image: {
    url: string
    width: number
    height: number
  }
  flipped: string  
  iconGroupCollection?: {
    items: {
      title?: string
      subTitle?: string
      description?: {
        json: Document
        content?: { content?: { value?: string }[] }[]
      }
      image?: {
        url: string
        width: number
        height: number
      }
    }[]
  }
  backgroundColor?: { colorArray: string[] }
  textColor?: { colorArray: string[] }
}

export function isContentfulImageNextToText(
  section: ContentfulSection
): section is ContentfulImageNextToText {
  return !!(
    (section as ContentfulImageNextToText).__typename === 'ImageNextToText'
  )
}

type ContentfulRichPageContent = ContentfulSectionCreator<'RichPageContent'> & {
  content?: {
    json?: Document
  }
  align?: string[]
  maxWidth?: string[]
}
export function isContentfulRichPageContent(
  section: ContentfulSection
): section is ContentfulRichPageContent {
  return !!(
    (section as ContentfulRichPageContent).__typename === 'RichPageContent'
  )
}

type ContentfulImageWithTextOverlay =
  ContentfulSectionCreator<'ImageWithTextOverlay'> & {
    titleLineHeight?: string[]
    maxHeight?: string[]
    contentPadding?: string[]
    preTitle?: string
    titleTag?: string
    buttonText?: string
    buttonUrl?: string
    secondButtonText?: string
    secondButtonUrl?: string
    id: string
    underlineButton: boolean
    title?: string
    description?: { json?: { content?: { content?: { value?: string }[] }[] } }
    image: {
      url: string
      width: number
      height: number
    }
    horizontalPosition: ComponentProps<
      typeof ImageWithTextOverlay
    >['horizontalPosition']
    verticalPosition: ComponentProps<
      typeof ImageWithTextOverlay
    >['verticalPosition']
    textAlign: ComponentProps<typeof ImageWithTextOverlay>['textAlign']
    minimumImageHeight: string
    textColor: string
    contentColor?: { colorArray: string[] }
    contentMaxWidth: string
    titleSize: ComponentProps<typeof ImageWithTextOverlay>['titleSize']
    imageFocusPosition: ComponentProps<
      typeof ImageWithTextOverlay
    >['imageFocusPosition']
    minHeight?: string[]
  }

export function isContentfulImageWithTextOverlay(
  section: ContentfulSection
): section is ContentfulImageWithTextOverlay {
  return !!(
    (section as ContentfulImageWithTextOverlay).__typename ===
    'ImageWithTextOverlay'
  )
}

type ContentfulProductTileSection =
  ContentfulSectionCreator<'ProductTileSection'> & {
    sections: {
      title: string
      description: {
        description: string
      }
      buttonText: string
      handle: string
      image: {
        url: string
        height: number
        width: number
      }
      color: {
        colorArray: string[]
      }
    }[]
  }

export function isContentfulProductTileSection(
  section: ContentfulSection
): section is ContentfulProductTileSection {
  return !!(
    (section as ContentfulProductTileSection).__typename ===
    'ProductTileSection'
  )
}

type ContentfulPressLogoList = ContentfulSectionCreator<'PressLogoList'> & {
  linksCollection: {
    items: {
      url: string
      image: {
        url: string
      }
      quote: string
      publicationName: string
    }[]
  }
  backgroundColor: {
    colorArray: string[]
  }
  asSeenText?: string
}

export function isContentfulInstagramSection(
  section: ContentfulSection
): section is ContentfulInstagramSection {
  return !!(
    (section as ContentfulInstagramSection).__typename === 'InstagramSection'
  )
}

type ContentfulInstagramSection =
  ContentfulSectionCreator<'InstagramSection'> & {
    handle: string
    title: string
    instasubtitle: string
    postsCollection?: {
      items: {
        image?: {
          url: string
        }
        alt?: string
        url?: string
      }[]
    }
  }
export function isContentfulPressLogoList(
  section: ContentfulSection
): section is ContentfulPressLogoList {
  return !!((section as ContentfulPressLogoList).__typename === 'PressLogoList')
}

export type ContentfulFaq = ContentfulSectionCreator<'Faq'> & {
  title: string
  questionsCollection: {
    items: Array<{
      sys: { id }
      question: string
      answer: {
        json: any
      }
    }>
  }
  // sys: {
  //   contentType: {
  //     sys: {
  //       id: string
  //     }
  //   }
  // }
}

export function isContentfulFaq(
  section: ContentfulSection
): section is ContentfulFaq {
  return !!((section as ContentfulFaq).__typename === 'Faq')
}

export type ContentfulQuiz = {
  title: string
  discountCode?: string
  discountText?: string
  klaviyoListId?: string
  chargifyDiscountCode?: string
  questions: {
    items: ContentfulQuestion[]
  }
  __typename: 'Quiz'
  sys: {
    id: string
  }
  headerBlock: {
    sys: {
      id: string
    }
  }
  successHeaderBlock: {
    sys: {
      id: string
    }
  }
}

export function isContentfulQuiz(
  section: ContentfulSection
): section is ContentfulQuiz {
  return !!((section as ContentfulQuiz).__typename === 'Quiz')
}

// all types union
export type ContentfulSection =
  | ContentfulTitleSection
  | ContentfulCollectionProductGrid
  | ContentfulDropdownLinkList
  | ContentfulImageNextToText
  | ContentfulImageWithTextOverlay
  | ContentfulProductTileSection
  | ContentfulPressLogoList
  | ContentfulFaq
  | ContentfulInstagramSection
  | ContentfulSplitHero
  | ContentfulIconFeaturesSection
  | ContentfulRoundedImage
  | ContentfulRichPageContent
  | ContentfulContactForm
  | ContentfulEmbedHTML
  | ContentfulDynamicProductDescription
  | ContentfulDynamicImageWithTextOverlay
  | ContentfulProductVideoReview
  | ContentfulQuiz
  | ContentfulSubscribeSection
  | ContentfulTwoButtonTextSection
  | ContentfulContentSection
  | ContentfulSMSSubscribeSection
