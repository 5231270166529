import useSWR from 'swr'
import { AllShopifyProductsForQuiz } from '~/pages/api/shopify/all-products-for-quiz'

const url = `/api/shopify/all-products-for-quiz`

export function useAllShopifyProductsforQuiz() {
  return useSWR<Awaited<AllShopifyProductsForQuiz['data']>>(
    url satisfies AllShopifyProductsForQuiz['endpoint'],
    () => fetch(url).then((r) => r.json())
  )
}
