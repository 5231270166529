/** @jsxImportSource theme-ui */
import { jsx, Flex, Button } from 'theme-ui'

import { memo } from 'react'
import Link from 'next/link'
import { Box } from 'theme-ui'
import { ShopName } from 'src/constants'
import { ContentfulImage } from '~/features/image/contentful-image'
import { Styled } from '~/components/styled'

type Props = {
  title: string
  description: string
  buttonText: string
  src: string
  height: number
  width: number
  handle: string
  color?: string[]
  index: number
}

const defaultProps = {
  color: ['background'],
}
const { color } = defaultProps

const ProductTile = memo(function Tile(props: Props) {
  const {
    title,
    description,
    buttonText,
    handle,
    index,
    src,
    // color = defaultProps.color,
  } = props
  return (
    <Box
      sx={{
        position: 'relative',
        textAlign: 'center',
        color,
        width: [null, '50%'],
        // m: [2, 3],
        // borderRadius: 3,
        // overflow: 'hidden',
      }}
    >
      <ContentfulImage
        // fluid={{ ...fluid, sizes: '(min-width: 768px) 800px, 100vw' }}
        src={src}
        sx={{ height: ['130vw', '55vw', '50vw', '45vw'] }}
        style={{ objectPosition: 'top center' }}
        alt={`${ShopName} | ${title}. ${description}.`}
        loading={index === 0 ? 'eager' : undefined}
        height={props.height}
        width={props.width}
        // fadeIn={index === 0 ? false : true}
      />
      <Flex
        sx={{
          position: 'absolute',
          inset: 0,
          alignItems: 'flex-end',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ mx: 3, mb: 6, maxWidth: 8 }}>
          <Styled.h2
            sx={{
              fontWeight: 'bold',
              color: 'background',
              mb: 1,
              fontSize: [8, 8, 9],
            }}
          >
            {title}
          </Styled.h2>
          {/* <Text>{description}</Text> */}
          <Link href={`/products/${handle}`} sx={{ variant: 'links.default' }}>
            <Button variant="outlineBackground" sx={{ mt: 0 }}>
              {buttonText}
            </Button>
          </Link>
        </Box>
      </Flex>
    </Box>
  )
})

export default ProductTile
