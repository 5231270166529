/** @jsxImportSource theme-ui */
import { Text } from 'theme-ui'

import Image from 'next/image'
import Link from 'next/link'
import React, { ComponentPropsWithoutRef, CSSProperties, useMemo } from 'react'
import { Box, Button, Container, Flex } from 'theme-ui'
import Head from 'next/head'

// import { useResponsiveValue } from '@theme-ui/match-media'
import { ShopName } from 'src/constants'
import { useTheme } from 'src/hooks/use-theme'
import { useWhyDidYouUpdate } from 'src/hooks/use-why-did-you-update'
import { Styled } from '~/components/styled'
import { ContentfulImage } from '~/features/image/contentful-image'
import { ContentfulRichText } from '../Contentful-Rich-Text'

type Props = {
  preTitle?: string
  title: string | null
  titleTag?: string | null
  titleSize?: 'normal' | 'big' | 'huge'
  description?: any
  buttons?: {
    text: string
    to?: string | null
    variant?: string | null
  }[]
  verticalPosition?:
    | ('top' | 'center' | 'bottom')[]
    | ('top' | 'center' | 'bottom')
  horizontalPosition?: 'left' | 'center' | 'right'
  textAlign?: 'left' | 'center' | 'right'
  imageFocusPosition?:
    | CSSProperties['objectPosition']
    | CSSProperties['objectPosition'][]
  /**
   * If false, image will be set to container
   */
  stretch?: boolean
  /**
   * Array that matches up to theme for height
   */
  height?: (string | number | 'full-screen')[]
  /**
   * Array that matches up to theme for maxHeight
   */
  maxHeight?: (string | number | 'full-screen')[]
  /**
   * Array that matches up to theme for minHeight
   */
  minHeight?: (string | number | 'full-screen' | null)[]
  /**
   * Array that matches up to theme for text color
   */
  textColor?: (string | number | null)[]
  /**
   * Array that matches up to theme for content width on tablet and greater
   */
  contentMaxWidth?: (string | number | null)[]
  /**
   * Where should text be positioned relative to the image on mobile screens.
   * Can be `cover image` or `under image`.
   *
   * Default: `under image`
   */
  contentMobilePosition?: 'cover image' | 'under image'
  contentPadding?: string[]
  titleLineHeight?: string[]
  image: {
    url: string
    width: number
    height: number
  }
  secondButtonText?: string
  secondButtonUrl?: string
} & ComponentPropsWithoutRef<typeof Image>

const defaultProps: Partial<Props> = {
  minHeight: [8, '70vh'],
  textColor: [null, 'background'],
  contentMaxWidth: [null, '70%'],
  verticalPosition: ['center'],
}

const ImageWithTextOverlay = (props: Props) => {
  const {
    verticalPosition: verticalPositionArray = defaultProps.verticalPosition,
    textAlign = 'center',
    title,
    titleTag = 'h2',
    buttons,
    description,
    minHeight: minHeightArray = defaultProps.minHeight,
    maxHeight,
    stretch = true,
    imageFocusPosition = 'center center',
    height: heightArray,
    horizontalPosition,
    contentMaxWidth = defaultProps.contentMaxWidth,
    titleSize = 'normal',
    contentMobilePosition = 'under image',
    // textColor = [
    //   contentMobilePosition === 'cover image' ? 'background' : null,
    //   'background',
    // ],
    textColor = defaultProps.textColor,
    preTitle,
    contentPadding,
    titleLineHeight,
    image,
    loading,
    ...imageProps
  } = props
  const { fontSizes } = useTheme()
  const verticalPosition = Array.isArray(verticalPositionArray)
    ? verticalPositionArray
    : [verticalPositionArray]

  useWhyDidYouUpdate('[image-with-text-overlay]', props)

  // console.log('[image-with-text-overlay]', props)
  // // console.log('[image-with-text-overlay]', { textColor })

  let titleFontSize = titleSize === 'normal' ? [7] : [7, 8]
  if (titleSize === 'huge') titleFontSize = [7, 8, 9]

  let descriptionFontSize = titleSize === 'normal' ? [3] : [4, 5]
  if (titleSize === 'huge') descriptionFontSize = [5, 6]

  const minHeight = minHeightArray?.map((h) =>
    h === 'full-screen' ? '100vh' : h
  )

  let justifyContent = ['center']
  if (horizontalPosition === 'left') {
    justifyContent = [...justifyContent, 'flex-start']
  } else if (horizontalPosition === 'right') {
    justifyContent = [...justifyContent, 'flex-end']
  }

  let buttonJustifyContent = textAlign === 'center' ? 'center' : 'flex-start'
  if (textAlign === 'right') buttonJustifyContent = 'flex-end'

  // TODO FIX THIS TO JUST USE CSS TO AVOID JUMPS
  // REMOVE useResponsiveValue
  // let alignItems = 'center'
  // if (verticalPosition === 'top') {
  //   alignItems = 'flex-start'
  // } else if (verticalPosition === 'bottom') {
  //   alignItems = 'flex-end'
  // }

  const objectFit = stretch ? 'cover' : 'contain'

  const maybeRenderContent = useMemo(() => {
    if (!title?.trim() && !description?.trim()) return null
    let HeaderTag: any = Styled.h2
    if (titleTag == 'h1') {
      HeaderTag = Styled.h1
    }

    return (
      <Flex
        sx={{
          alignItems: verticalPosition?.map((position) => {
            switch (position) {
              case 'bottom':
                return 'flex-end'
              case 'top':
                return 'flex-start'
              case 'center':
                return 'center'
              default:
                return null
            }
          }),
          inset: 0,
          position: [
            contentMobilePosition === 'cover image' ? 'absolute' : 'static',
            'absolute',
          ],
        }}
        pt={[
          6,
          verticalPosition?.[1] === 'top' || verticalPosition?.[0] === 'top'
            ? 9
            : null,
        ]}
        pb={[
          6,
          verticalPosition?.[1] === 'bottom' ||
          verticalPosition?.[0] === 'bottom'
            ? 6
            : null,
        ]}
        px={3}
      >
        <Container sx={{ flex: 1, padding: contentPadding }}>
          <Flex sx={{ justifyContent }}>
            <Box sx={{ width: contentMaxWidth }}>
              {preTitle?.trim() && (
                <Text
                  sx={{
                    variant: 'text.preHeader',
                    textAlign,
                    fontSize: descriptionFontSize.map(
                      (font) => `calc(${fontSizes[font] ?? font} * 0.8)`
                    ),
                    mb: 2,
                  }}
                >
                  {preTitle}
                </Text>
              )}
              {!!title.trim() && (
                <HeaderTag
                  sx={{
                    color: textColor,
                    textAlign,
                    // fontSize: [null, 8, 9, 10],
                    fontSize: [null, ...titleFontSize],
                    lineHeight: titleLineHeight,
                  }}
                >
                  {title}
                </HeaderTag>
              )}
              {!!description && (
                <div
                  sx={{
                    textAlign,
                    color: textColor,
                    fontSize: [null, ...descriptionFontSize],
                    width: '100%',
                  }}
                >
                  <ContentfulRichText json={description.json} />
                </div>
              )}
              {!!buttons?.length && (
                <Flex
                  marginTop={3}
                  sx={{
                    flexWrap: 'wrap',
                    justifyContent: buttonJustifyContent,
                    gap: 4,
                  }}
                >
                  {buttons?.map(({ variant, text, to }, index) => {
                    if (!text?.trim()) return null
                    return to ? (
                      <Link key={to} href={to}>
                        <Button
                          // sx={{ variant: 'outlineBackground' }}
                          variant={variant ?? 'pill'}
                        >
                          {text}
                        </Button>
                      </Link>
                    ) : (
                      <Button
                        key={index.toString()}
                        variant={variant ?? 'outlineBackground'}
                        // sx={{ variant: variant ?? 'outlineBackground' }}
                      >
                        {text}
                      </Button>
                    )
                  })}
                </Flex>
              )}
            </Box>
          </Flex>
        </Container>
      </Flex>
    )
  }, [
    buttonJustifyContent,
    buttons,
    contentMaxWidth,
    contentMobilePosition,
    contentPadding,
    description,
    descriptionFontSize,
    fontSizes,
    justifyContent,
    preTitle,
    textAlign,
    textColor,
    title,
    titleFontSize,
    titleLineHeight,
    verticalPosition,
  ])

  return (
    <>
      {/* {loading == 'eager' && (
        <Head>
          <link rel="preload" href={`${image.url}?fm=webp`} as="image" />
        </Head>
      )} */}
      <Box sx={{ position: 'relative', height: ['100vh', 'unset'] }}>
        {/* <a id={title?.toLowerCase().replace(/\ /g, '-')}></a> */}
        <ContentfulImage
          {...imageProps}
          alt={`${ShopName} | ${title}`}
          width={image.width}
          height={image.height}
          sizes="100vw"
          sx={{
            variant: 'styles.imageWithTextOverlayContainer',
            position: 'relative',
            minHeight,
            maxHeight: maxHeight ? [...maxHeight, 'none'] : 'none',
            aspectRatio: image ? image.width / image.height : 1,
            width: '100%',
            height: ['100%', 'auto'],
            objectFit,
            objectPosition: imageFocusPosition,
          }}
          loading={loading}
        />
        {maybeRenderContent}
      </Box>
    </>
  )
}

export default React.memo(ImageWithTextOverlay)
