/** @jsxImportSource theme-ui */
import { Text } from 'theme-ui'

import { BLOCKS, Document } from '@contentful/rich-text-types'
import Image from 'next/image'
import React, { ComponentPropsWithoutRef } from 'react'
import { ShopName } from 'src/constants'
import { Box, Button, Flex } from 'theme-ui'
import { Styled } from '~/components/styled'
import { ContentfulImage } from '~/features/image/contentful-image'
import ThemeUi from '~/styles/theme'
import { ContentfulRichText } from './Contentful-Rich-Text'
import { SmartLink } from './Smart-Link'

type Props = {
  title: string | null
  titleTag?: string
  description?: string
  buttons?: {
    text: string
    to?: string | null
    variant?: string | null
  }[]
  contentSide?: 'left' | 'right'
  containerProps?: ComponentPropsWithoutRef<typeof Box>
  json?: Document
  bulletPoints?: {
    text?: string
    icon?: {
      url: string
      height: number
      width: number
    }
  }[]
  iconGroup?: {
    title?: string
    subTitle?: string
    description?: {
      json: Document
    }
    image?: {
      url: string
      height: number
      width: number
    }
  }[]
  height: number
  width: number
  backgroundColor?: {
    colorArray: string[]
  }
  textColor?: {
    colorArray: string[]
  }
} & ComponentPropsWithoutRef<typeof Image>

const ImageNextToText = (props: Props) => {
  const {
    title,
    titleTag="h2",
    description,
    src,
    width,
    height,
    buttons,
    contentSide = 'left',
    containerProps,
    json,
    bulletPoints,
    iconGroup,
    backgroundColor,
    textColor,
  } = props

  let paddingLeft = (t: typeof ThemeUi): (string | number)[] => [4, 5, 8]
  let paddingRight = (t: typeof ThemeUi): (string | number)[] => [4, 5, 8]
  const marginLeft = ['0']
  const marginRight = ['0']
  if (contentSide === 'left') {
    paddingRight = (t) => [
      4,
      `calc(${t.space['4']} + ${t.space['4']} + 13%)`,
      `calc(${t.space['5']} + ${t.space['5']} + 13%)`,
      `calc(${t.space['7']} + ${t.space['7']} + 13%)`,
    ]
    marginRight[1] = '-13%'
  } else if (contentSide === 'right') {
    paddingLeft = (t) => [
      4,
      `calc(${t.space['4']} + ${t.space['4']} + 13%)`,
      `calc(${t.space['5']} + ${t.space['5']} + 13%)`,
      `calc(${t.space['7']} + ${t.space['7']} + 13%)`,
    ]
    marginLeft[1] = '-13%'
  }

  const renderBulletPoints = () => {
    if (!bulletPoints) return null

    return (
      <Box sx={{ my: [5, 3] }}>
        {bulletPoints?.map(({ text, icon }, i) => {
          return (
            <Flex
              sx={{
                alignItems: 'center',
                justifyContent: ['center', 'flex-start'],
                my: [2, 1],
              }}
              key={`${text}-${i}`}
            >
              <Box sx={{ marginRight: 3 }}>
                {icon && (
                  <ContentfulImage
                    alt={text || 'Icon'}
                    src={icon?.url}
                    {...icon}
                    width={20}
                    height={20}
                    sx={{
                      width: '20px',
                      height: 'auto',
                      display: 'block',
                      margin: 0,
                    }}
                    loading="lazy"
                    style={{ objectFit: 'contain' }}
                  />
                )}
              </Box>
              <Box>{text}</Box>
            </Flex>
          )
        })}
      </Box>
    )
  }

  const renderIconGroup = () => {
    if (!iconGroup) return null

    return (
      <Box sx={{ my: [5, 3] }}>
        {iconGroup?.map(({ title, subTitle, description, image }, i) => {
          return (
            <Flex
              sx={{
                alignItems: 'center',
                justifyContent: ['flex-start'],
                gap: 4,
                my: [2, 1],
              }}
              key={`${title}-${i}`}
            >
              {image && (
                <ContentfulImage
                  alt={title || 'Icon'}
                  src={image?.url}
                  {...image}
                  width={100}
                  height={100}
                  loading="lazy"
                  sx={{ objectFit: 'contain', m:0 }}
                />
              )}
              <Flex sx={{ flexDirection: 'column', textAlign:"left" }}>
                {title && (
                  <Text sx={{ fontSize: 4, fontWeight: 'bold' }}>{title}</Text>
                )}
                {subTitle && <Text sx={{ fontSize: 2 }}>{subTitle}</Text>}
                {description?.json && (
                  <ContentfulRichText
                    renderNode={
                      bulletPoints?.length
                        ? {
                            [BLOCKS.PARAGRAPH]: (_, c) => <Text>{c}</Text>,
                          }
                        : undefined
                    }
                    json={description?.json}
                  />
                )}
              </Flex>
            </Flex>
          )
        })}
      </Box>
    )
  }
  
  let TitleTag:any = Styled.h2;
  if (titleTag == "h1") {
    TitleTag = Styled.h1;
  } else if (titleTag == "h2") {
    TitleTag = Styled.h2;
  } else if (titleTag == "h3") {
    TitleTag = Styled.h3;
  } 

  return (
    <Box id={title?.toLowerCase().replace(/ /g, '-')} {...containerProps}>
      {/* <a id={title?.toLowerCase().replace(/\ /g, '-')}></a> */}
      <Flex
        sx={{
          display: ['block', 'flex'],
          flexDirection: [null, contentSide === 'left' ? 'row-reverse' : 'row'],
        }}
      >
        <Box
          sx={{
            my: [null, 9],
            // gridColumn: [null, 'span 1 / auto'],
            // gridRow: [null, 'span 1 / auto'],
            display: [null, 'flex'],
            flexDirection: [null, 'column'],
            justifyContent: [null, 'center'],
            flex: [null, 1],
          }}
        >
          <Box pb={`${(height / width) * 100}%`} sx={{ position: 'relative' }}>
            {!!src && (
              <Image
                src={src}
                sizes="(min-width: 780px) 50vw, 100vw"
                alt={`${ShopName} | ${title}`}
                fill
                loading="lazy"
              />
            )}
          </Box>
        </Box>
        <Flex
          sx={{
            // gridRow: contentSide === 'left' ? [null, 1] : undefined,
            flex: [null, 1],
          }}
        >
          <Box
            sx={{
              paddingLeft,
              paddingRight,
              marginRight,
              marginLeft,
              width: [null, '113%'],
              textAlign: ['center', 'left'],
              py: [6, 8],
              color: textColor?.colorArray || 'text',
              bg: backgroundColor?.colorArray || 'background',
              display: [null, 'flex'],
              flexDirection: [null, 'column'],
              justifyContent: [null, 'center'],
            }}
          >
            <Box>
              <TitleTag
                sx={{
                  fontSize: [null, null, 6, 7, 8],
                  color: textColor?.colorArray || 'text',
                }}
              >
                {title}
              </TitleTag>
              <div
                sx={{
                  textAlign: ['left'],
                  paddingX: [5, 0],
                }}
              >
                {json && (
                  <ContentfulRichText
                    renderNode={
                      bulletPoints?.length
                        ? {
                            [BLOCKS.PARAGRAPH]: (_, c) => <Text>{c}</Text>,
                          }
                        : undefined
                    }
                    json={json}
                  />
                )}
              </div>

              {renderBulletPoints()}
              {renderIconGroup()}
              <Flex
                marginTop={bulletPoints?.length ? 0 : 3}
                sx={{
                  flexWrap: 'wrap',
                  justifyContent: ['center', 'flex-start'],
                }}
              >
                {buttons?.map(({ variant, text, to }, index) => {
                  if (!text.trim()) return null
                  // only odd buttons have a margin
                  const mx = index % 2 ? 1 : 0
                  return !!to ? (
                    <SmartLink key={to} to={to}>
                      <Button mx={mx} variant={variant ?? 'primary'}>
                        {text}
                      </Button>
                    </SmartLink>
                  ) : (
                    <Button
                      key={`${text}${index.toString()}`}
                      mx={mx}
                      variant={variant ?? 'primary'}
                    >
                      {text}
                    </Button>
                  )
                })}
              </Flex>
            </Box>
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}

export default React.memo(ImageNextToText)
