import { GetStaticProps } from 'next'
import { PageHeaderProps } from '~/api/header-settings'
import { getStampedReviewSummariesForAllProducts } from '~/api/stamped'
import { queryShopify } from './queryShopify'

export const queryShopifyRest = async <Returns extends any>(
  endpoint: `/${string}`,
  body?: {}
) => {
  const headers = new Headers()
  headers.append('X-Shopify-Access-Token', process.env.SHOPIFY_ADMIN_PASSWORD!)
  headers.append('Content-Type', 'application/json')
  return fetch(
    `https://${process.env.NEXT_PUBLIC_SHOP_NAME}.myshopify.com/admin/api/${process.env.NEXT_PUBLIC_SHOPIFY_API_VERSION}${endpoint}`,
    {
      method: 'GET',
      headers,
      body: body && JSON.stringify(body),
    }
  ).then((r) => {
    const result = r.json()
    const pageInfo = r.headers
      .get('link')
      ?.split(';')?.[0]
      ?.replace('>', '')
      ?.replace('<', '')
    return result as Promise<Returns>
  })
}

export const postShopifyRest = async <Returns extends any>(
  endpoint: `/${string}`,
  body?: {}
) => {
  const headers = new Headers()
  headers.append('X-Shopify-Access-Token', process.env.SHOPIFY_ADMIN_PASSWORD!)
  headers.append('Content-Type', 'application/json')
  return fetch(
    `https://${process.env.NEXT_PUBLIC_SHOP_NAME}.myshopify.com/admin/api/${process.env.NEXT_PUBLIC_SHOPIFY_API_VERSION}${endpoint}`,
    {
      method: 'POST',
      headers,
      body: body && JSON.stringify(body),
    }
  ).then((r) => {
    const result = r.json()
    return result as Promise<Returns>
  })
}

export const queryContentful = async <Returns extends any>(
  query: any,
  variables?: {}
) => {
  const headers = new Headers()
  headers.append(
    'Authorization',
    `Bearer ${process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN}`
  )
  headers.append('Content-Type', 'application/json')

  const url = `https://graphql.contentful.com/content/v1/spaces/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}/environments/master`

  return fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query,
      variables,
    }),
  }).then(async (r) => {
    const result = await r.json()
    // @ts-ignore
    if (result.error) {
      // @ts-ignore
      console.error(query.slice(0, 40), result.error)
    }
    return result as Promise<{ data: Returns }>
  })
}


type AllShopifyProducts = {
  products: {
    nodes: {
      title: string
      handle: string
      totalVariants: number
      status: 'ACTIVE' | 'DRAFT'
      // popularVariants: {
      //   nodes: Array<{
      //     id: string
      //     title: string
      //     price: string
      //     availableForSale: boolean
      //     sku: string
      //     image: {
      //       height: number
      //       width: number
      //       url: string
      //     } | null
      //     selectedOptions: Array<{
      //       name: string
      //       value: string
      //     }>
      //   }>
      // }
      priceRange: {
        maxVariantPrice: {
          amount: string
          currencyCode: string
        }
        minVariantPrice: {
          amount: string
          currencyCode: string
        }
      }
      shopifyId: string
    }[]
  }
}

export const withPageProps = async <
  Props extends {
    headerProps: PageHeaderProps
  } & {
    [key: string]: any
  },
>(
  props: Props
) => {
  const [
    headerAndFooterSettings,
    products,
    stampedSummaries,
    productGridItems,
    cartFreeShipping,
  ] = await Promise.all([
    queryContentful<GlobalAppProps>(
      `fragment HeaderLinkList on LinkList {
      title
      linksCollection {
        items {
          name
          url
          dropdown {
            title
            linksCollection(limit: 10) {
              items {
                text
                image {
                  url
                  height
                  width
                }
                url
              }
            }
          }
          customDropdown {
            title
            linksCollection(limit: 10) {
              items {
                text
                image {
                  url
                  height
                  width
                }
                url
              }
            }
          }
        }
      }
    }
    
    query AppQuery {
      footerSettings(id: "EuZxKiJ4M1EJLMTCHnJ9c") {
        disclaimer
        disclaimerTitle
        handle
        socialLinksCollection(limit: 6) {
          items {
            logo {
              url
              height
              width
            }
            url
            socialName
          }
        }
        linkListsCollection(limit:10) {
          items {
            title
            linksCollection {
              items {
                name
                url
              }
            }
          }
        }
        showSubscribeSection
        subscribeSection {
          subscribeText
          buttonText
          backgroundColor {
            colorArray
          }
          textColor {
            colorArray
          }
          successText
        }
      }
      mainHeaderSettings(id: "6T0e4aD8AQKLXu9tlTxZTR") {
        handle
        mobileLinkList {
          ...HeaderLinkList
        }
        rightMenuLinkList {
          title
          linksCollection {
            items {
              name
              url
            }
          }
        }
        leftMenuLinkList {
          ...HeaderLinkList
        }
      }
    }`,
      {}
    ),
    queryShopify<AllShopifyProducts>(
      `#graphql
      query AllProducts {
      products(first: 60) {
        nodes {
          title
          handle

          priceRange: priceRangeV2 {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          shopifyId: id
          id
          status
          updatedAt
        }
      }
    }`,
      undefined
    ),
    getStampedReviewSummariesForAllProducts(),
    queryContentful<{
      collectionGridItemCollection: {
        items: Array<{
          productHandle: string
          imagesCollection: {
            items: Array<{
              url: string
              height: number
              width: number
            }>
          }
        }>
      }
    }>(
      `query AppQuery {
          collectionGridItemCollection(limit:100) {
            items {
              productHandle
              imagesCollection {
                items {
                  url
                  height
                  width
                }
              }
            }
          }
        }`,
      {}
    ),
    queryShopify<CartFreeShipping>(
      `#graphql
          query cartFreeShipping {
            metaobjectByHandle(handle: {
              type: "cart_free_shipping",
              handle: "cart-free-shipping"
            }) {
            displayName
            price: field(key:"price") {
              value
            }
            freeShippingPrice: field(key:"free_shipping_price"){
              value
            }
            items: field(key:"items") {
              references(first: 100){
                nodes {
                  ... on Metaobject {
                    price: field(key:"price"){
                      value
                    }
                    product: field(key:"product"){
                      value
                      reference {
                        ... on ProductVariant{
                          id
                          displayName
                          title
                          compareAtPrice
                          price
                          image {
                            altText
                            url
                          }
                          product {
                            title
                            featuredImage {
                              altText
                              url
                            }
                            handle
                            productType
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }`
    ),
  ])
  if (!products?.data) {
    throw new Error('No products found ' + JSON.stringify(products, null, 2))
  }

  return {
    ...props,
    ...headerAndFooterSettings.data,
    allShopifyProduct: products.data.products.nodes.filter(
      (node) => node.status === 'ACTIVE'
    ),
    allStampedReviewSummaries: stampedSummaries,
    productGridItems: productGridItems.data.collectionGridItemCollection.items,
    cartFreeShipping: cartFreeShipping?.data.metaobjectByHandle || null,
  }
}

type ExtractProps<T> = T extends { props: infer Props } ? Props : never

type Prettify<T> = {
  [K in keyof T]: T[K]
}

export type InferPageProps<GSP extends GetStaticProps> = Prettify<
  ExtractProps<Awaited<ReturnType<GSP>>>
>

interface Link {
  name: string
  url: string
}

interface DropdownLink {
  text: string
  image: {
    url: string
    height: number
    width: number
  }
  url: string
}

interface Dropdown {
  title: string
  linksCollection: {
    items: DropdownLink[]
  }
}

interface MenuItem {
  name: string
  url: string
  dropdown: Dropdown
  customDropdown: Dropdown
}

interface LinkList {
  title: string
  linksCollection: {
    items: Link[]
  }
}

interface MenuLinkList {
  title: string
  linksCollection: {
    items: MenuItem[]
  }
}

interface FooterSettings {
  handle: string
  linkListsCollection: {
    items: LinkList[]
  }
  disclaimer?: string
  disclaimerTitle?: string
  socialLinksCollection: {
    items: {
      logo: {
        url: string
        height: number
        width: number
      }
      url: string
      socialName: string
    }[]
  }
  showSubscribeSection?: boolean
  subscribeSection?: {
    subscribeText?: string
    buttonText?: string
    backgroundColor: { colorArray: [] }
    textColor: { colorArray: [] }
    successText?: string
  }
}

interface MainHeaderSettings {
  handle: string
  leftMenuLinkList: MenuLinkList
  rightMenuLinkList: MenuLinkList
  mobileLinkList: MenuLinkList
}

export interface GlobalAppProps {
  footerSettings: FooterSettings
  mainHeaderSettings: MainHeaderSettings
}

interface CartFreeShipping {
  metaobjectByHandle: {
    displayName: string
    items?: {
      references: {
        nodes: {
          price: {
            value: string
          }
          product: {
            value: string
            reference: {
              compareAtPrice?: string
              displayName: string
              price: string
              image: {
                altText?: string
                url: string
              }
              product: {
                title: string
                featuredImage: {
                  altText?: string
                  url: string
                }
              }
            }
          }
        }[]
      }
    }
    price: {
      value: string
    }
  }
}