/** @jsxImportSource theme-ui */
import {
  RenderNode,
  documentToReactComponents,
} from '@contentful/rich-text-react-renderer'
import { BLOCKS, Document, INLINES, MARKS } from '@contentful/rich-text-types'
import React, { useRef } from 'react'
import { Styled } from '~/components/styled'

export const ContentfulRichText = React.memo(function ContentfulRichText({
  json,
  renderNode = {},
}: {
  json: Document
  renderNode?: RenderNode
}) {
  const registeredIds = useRef<Record<string, string>>({})
  const stringToNodeID = (str?: string) => {
    const id =
      typeof str === 'string'
        ? str.toLowerCase().trim().replace(/\s/g, '-')
        : undefined
    if (typeof id === 'string' && !(id in registeredIds.current)) {
      registeredIds.current[id] = id
      return id
    }
    return undefined
  }
  return (
    <React.Fragment>
      {documentToReactComponents(json, {
        renderMark: {
          [MARKS.BOLD]: (text) => <strong>{text}</strong>,
          [MARKS.ITALIC]: (text) => <em>{text}</em>,
        },
        renderNode: {
          [BLOCKS.PARAGRAPH]: (_, children) => <Styled.p>{children}</Styled.p>,
          [BLOCKS.HEADING_1]: ({ content }, children) => {
            return (
              <Styled.h1 id={stringToNodeID(content[0]?.value)}>
                {children}
              </Styled.h1>
            )
          },
          [BLOCKS.HEADING_2]: ({ content }, children) => {
            // // console.log('[contentful-rich]', { content })
            return (
              <Styled.h2 id={stringToNodeID(content[0]?.value)}>
                {children}
              </Styled.h2>
            )
          },
          [BLOCKS.HEADING_3]: ({ content }, children) => (
            <Styled.h3 id={stringToNodeID(content[0]?.value)}>
              {children}
            </Styled.h3>
          ),
          [BLOCKS.HEADING_4]: ({ content }, children) => (
            <Styled.h4 id={stringToNodeID(content[0]?.value)}>
              {children}
            </Styled.h4>
          ),
          [BLOCKS.HEADING_5]: ({ content }, children) => (
            <Styled.h5 id={stringToNodeID(content[0]?.value)}>
              {children}
            </Styled.h5>
          ),
          [BLOCKS.HEADING_6]: ({ content }, children) => (
            <Styled.h6 id={stringToNodeID(content[0]?.value)}>
              {children}
            </Styled.h6>
          ),
          [BLOCKS.EMBEDDED_ASSET]: (node) => {
            // // console.log('[contentful-rich-text] embedded asset', node)
            if (!node?.data?.target?.fields) return null

            const { title, description, file } = node.data.target.fields
            const mimeType = file['en-US'].contentType ?? file.contentType
            const mimeGroup = mimeType?.split('/')?.[0]

            switch (mimeGroup) {
              case 'image':
                return (
                  <img
                    title={title && 'en-us' in title ? title?.['en-US'] : title}
                    alt={
                      description && 'en-us' in description
                        ? description?.['en-US']
                        : description
                    }
                    src={
                      file && 'en-us' in file ? file?.['en-US']?.url : file?.url
                    }
                    // sx={{ borderRadius: 3, boxShadow: 'header' }}
                  />
                )
              // case 'application':
              //   return <a
              //     alt={description ?  description['en-US'] : null}
              //     href={file['en-US'].url}
              //     >{ title ? title['en-US'] : file['en-US'].details.fileName }
              //   </a>
              default:
                return null
              // return <span style={{backgroundColor: 'red', color: 'white'}}> {mimeType} embedded asset </span>
            }
          },
          [INLINES.HYPERLINK]: ({ data }, children) => {
            return (
              <Styled.a
                sx={{
                  textDecoration: 'none',
                  color: 'accent',
                  borderBottomStyle: 'solid',
                }}
                href={data.uri}
              >
                {children}
              </Styled.a>
            )
          },
          ...renderNode,
          //   [BLOCKS.EMBEDDED_ASSET]: node => <EmbeddedAsset node={node} />,
        },
      })}
    </React.Fragment>
  )
})

// export default () => <ContentfulRichText json={{ content }} />
