import React, { useMemo } from 'react'
import CollectionProductGrid from './Collection-Product-Grid'
import FAQ from './FAQ'
import { IconFeatures } from './Icon-Features'
import ImageNextToText from './ImageNextToText'
import ImageWithTextOverlay from './ImageWithTextOverlay'
import InstagramGallery from './Instagram-Gallery'
import { PressLogoList } from './Press-Logo-List'
import ProductTileSection from './Product-Tile-Section'
import { RichPageContent } from './Rich-Page-Content'
import { RoundedImage } from './Rounded-Image'
import { SplitHero } from './Split-Hero'
import TitleSection from './TitleSection'
import VideoReviewCarousel from './VideoReviewCarousel'
import {
  ContentfulSection,
  isContentfulCollectionProductGrid,
  isContentfulContactForm,
  isContentfulContentSection,
  isContentfulDynamicImageWithTextOverlay,
  isContentfulDynamicProductDescription,
  isContentfulEmbedHTML,
  isContentfulFaq,
  isContentfulIconSection,
  isContentfulImageNextToText,
  isContentfulImageWithTextOverlay,
  isContentfulInstagramSection,
  isContentfulPressLogoList,
  isContentfulProductTileSection,
  isContentfulProductVideoReview,
  isContentfulQuiz,
  isContentfulRichPageContent,
  isContentfulRoundedImage,
  isContentfulSMSSubscribeSection,
  isContentfulSplitHero,
  isContentfulSubscribeSection,
  isContentfulTitleSection,
  isContentfulTwoButtonTextSection,
  isDropdownLinkList,
} from './types'

// import { ContactForm } from '../Contact-Form'
import dynamic from 'next/dynamic'
import { Quiz } from '~/features/contentful-sections/quiz/quiz'
import { ContentSection } from './ContentSection'
import EmbedHTML from './Embed-HTML'
import { SMSSubscribeSection } from './SMSSubscribeSection'
import { SubscribeSection } from './SubscribeSection'
import { TwoButtonTextSection } from './TwoButtonTextSection'
import ImageCardGrid from './Image-Card-Grid'

const ContactForm = dynamic(() => import('./Contact-Form'), {
  ssr: false,
})
// const EmbedHTML = loadable(() => import('../Embed-HTML'), {
//   ssr: false,
// })

type Props = {
  sections?: ContentfulSection[] | null
  isProductPage?: boolean
  sku?: string
}

const ContentfulSections = (props: Props) => {
  const { sections, isProductPage, sku } = props

  const renderSections = useMemo(() => {
    if (!sections?.length) return null

    return sections.map((section, index) => {
      if (!section?.__typename) return null
      const id = section.sys.id

      if (isContentfulImageWithTextOverlay(section)) {
        if (!section.image) return null
        const {
          buttonText,
          buttonUrl,
          underlineButton,
          title,
          titleTag,
          description,
          image,
          horizontalPosition,
          verticalPosition,
          textAlign,
          contentMaxWidth,
          titleSize,
          imageFocusPosition,
          minHeight,
          contentColor,
          contentMobilePosition,
          preTitle,
          contentPadding,
          maxHeight,
          titleLineHeight,
          secondButtonText,
          secondButtonUrl,
        } = section

        let buttons = [
          buttonText && {
            text: buttonText,
            to: buttonUrl,
            variant: underlineButton ? 'underline' : 'pill',
          },
          secondButtonText && {
            text: secondButtonText,
            to: secondButtonUrl,
            variant: underlineButton ? 'underline' : 'pill',
          },
        ].filter(Boolean)

        buttons = buttons.length ? buttons : undefined
        return (
          <ImageWithTextOverlay
            maxHeight={maxHeight}
            contentPadding={contentPadding}
            key={`contentful-sections-image-weith-text-overlay-${id}`}
            titleLineHeight={titleLineHeight}
            buttons={buttons}
            alt={title ?? ''}
            preTitle={preTitle}
            minHeight={minHeight}
            contentMobilePosition={contentMobilePosition}
            title={title ?? ''}
            titleTag={titleTag || 'h2'}
            description={description
              // description?.json?.content
              //   ?.map((paragraph) => paragraph?.content?.[0]?.value)
              //   .join('\n') ?? ''
            }
            titleSize={titleSize}
            image={image}
            src={image.url}
            imageFocusPosition={imageFocusPosition}
            {...{ horizontalPosition, verticalPosition, textAlign }}
            // minHeight={[null, minimumImageHeight ?? null]}
            // textColor={[null, textColor ?? 'background']}
            textColor={contentColor?.colorArray}
            contentMaxWidth={[null, contentMaxWidth ?? '70%']}
            loading={index === 0 && !isProductPage ? 'eager' : 'lazy'}
            priority={index === 0 && !isProductPage }
            // fadeIn={index === 0 ? false : undefined}
          />
        )
      }

      if (isContentfulDynamicImageWithTextOverlay(section)) {
        const {
          image,
          horizontalPosition,
          verticalPosition,
          textAlign,
          contentMaxWidth,
          titleSize,
          imageFocusPosition,
          contentMobilePosition,
          minHeight,
          contentColor,
          contentPadding,
          maxHeight,
          titleLineHeight,
          textOptionsCollection,
          defaultTextOption,
        } = section
        const currentTextOption =
          textOptionsCollection?.items?.find((option) => {
            return sku && option.skus?.includes(sku)
          }) || defaultTextOption
        return (
          <ImageWithTextOverlay
            maxHeight={maxHeight}
            contentPadding={contentPadding}
            key={`contentful-sections-image-weith-text-overlay-${id}`}
            titleLineHeight={titleLineHeight}
            preTitle={currentTextOption?.preTitle}
            minHeight={minHeight}
            title={currentTextOption?.title ?? ''}
            description={
              currentTextOption?.description
            }
            image={image}
            titleSize={titleSize}
            alt={currentTextOption?.title ?? ''}
            src={image.url}
            imageFocusPosition={imageFocusPosition}
            contentMobilePosition={contentMobilePosition}
            {...{ horizontalPosition, verticalPosition, textAlign }}
            // minHeight={[null, minimumImageHeight ?? null]}
            // textColor={[null, textColor ?? 'background']}
            textColor={contentColor?.colorArray}
            contentMaxWidth={[null, contentMaxWidth ?? '70%']}
            loading={index === 0 && !isProductPage ? 'eager' : 'lazy'}
            priority={index === 0 && !isProductPage }
            // fadeIn={index === 0 ? false : undefined}
          />
        )
      }

      if (isContentfulImageNextToText(section)) {
        const {
          buttonText,
          buttonUrl,
          underlineButton,
          title,
          titleTag,
          description,
          image,
          flipped,
          bulletPointsCollection,
          iconGroupCollection,
          secondButtonText,
          secondButtonUrl,
          backgroundColor,
          textColor
        } = section
        return (
          <ImageNextToText
            alt={title ?? ''}
            key={`contentful-sections-image-next-to-text-${id}`}
            bulletPoints={bulletPointsCollection?.items}
            iconGroup={iconGroupCollection?.items}
            width={image.width ?? 1}
            height={image.height ?? 1}
            buttons={[
              buttonText?.trim() && {
                text: buttonText,
                to: buttonUrl,
                variant: underlineButton ? 'underline' : 'primary',
              },
              secondButtonText?.trim() && {
                text: secondButtonText,
                to: secondButtonUrl,
                variant: underlineButton ? 'underline' : 'primary',
              },
            ].filter(Boolean)}
            json={description?.json}
            title={title ?? ''}
            titleTag={titleTag}
            description={
              description?.content
                ?.map((paragraph) => paragraph?.content?.[0]?.value)
                .join('\n') ?? ''
            }
            src={image.url}
            contentSide={flipped ? 'right' : 'left'}
            containerProps={{
              sx: {
                py: [null, 7],
                bg: 'muted',
              },
            }}
            backgroundColor={backgroundColor}
            textColor={textColor}
          />
        )
      }

      if (isContentfulTitleSection(section)) {
        const {
          preTitle,
          headline,
          headlineTag,
          textAlign,
          headlineFontSize,
          fontWeight,
          subtitle,
          backgroundColor
        } = section
        return (
          <TitleSection
            key={`contentful-sections-title-section-${id}`}
            {...{
              preTitle,
              title: headline,
              titleTag: headlineTag,
              textAlign: [textAlign ?? 'center'],
              subtitle,
              fontWeight,
              headlineFontSize,
              backgroundColor
            }}
            style={{
              py: [5, 8],
              // pb: [6, 9]
              // pb: (t: typeof ThemeUi) => [
              //   `calc(${t.space['5']} + ${t.space['3']})`,
              //   `calc(${t.space['8']} + ${t.space['3']})`,
              // ],
            }}
            titleStyle={{
              mb: [4, 5],
              '&:last-of-type': {
                mb: 0,
              },
            }}
          />
        )
      }

      if (isContentfulCollectionProductGrid(section)) {
        const {
          // collectionHandle,
          itemsPerRow,
          itemsPerRowMobile,
          itemsPerRowTablet,
          showTitle,
          showDescription,
          backgroundColor,
          imageHeightPercentage,
          maxHeight,
          productsCollection,
          showReviewsCount,
          title,
          isLabReportsSection,
          labReportsCollection,
        } = section
        if (!productsCollection) return null
        return (
          // TODO fix
          <CollectionProductGrid
            itemsPerRow={itemsPerRow}
            itemsPerRow_mobile={itemsPerRowMobile}
            itemsPerRow_tablet={itemsPerRowTablet}
            // collectionHandle={collectionHandle}
            showTitle={showTitle}
            showDescription={showDescription}
            // productHandles={productHandles}
            title={title}
            key={`contentful-sections-collection-product-grid-${id}`}
            backgroundColor={backgroundColor?.colorArray}
            showReviewsCount={showReviewsCount}
            products={productsCollection?.items.map((item) => ({
              images: item.imagesCollection.items.map((image) => ({
                url: image.url,
              })),
              productHandle: item.productHandle,
            }))}
            itemMaxHeight={maxHeight}
            imageHeightPercentage={imageHeightPercentage}
            labReports={labReportsCollection?.items.map((item) => ({
              images: item.imagesCollection.items.map((image) => ({
                url: image.url,
                height: image.height,
                width: image.width,
              })),
              productHandle: item.productHandle,
            }))}
            isLabReportsSection={isLabReportsSection}
          />
        )
      }

      if (isDropdownLinkList(section)) {
        const {
          title,
          linksCollection
        } = section
        if (!linksCollection) return null
        return (
          // TODO fix
          <ImageCardGrid
            key={`contentful-sections-image-card-grid-${id}`}
            title={title}
            items={linksCollection?.items}
          />
        )
      }

      if (isContentfulProductTileSection(section)) {
        // // console.log('[contentful-sections] product tile', section)
        return (
          <ProductTileSection
            key={`contentful-sections-product-tile-sections-${id}`}
            tiles={section.sections.map(
              ({
                buttonText,
                description: { description },
                handle,
                image,
                title,
                color: { colorArray },
              }) => ({
                buttonText,
                description,
                src: image.url,
                height: image.height,
                width: image.width,
                handle,
                title,
                color: colorArray,
              })
            )}
          />
        )
      }

      if (isContentfulPressLogoList(section)) {
        const {
          linksCollection,
          asSeenText,
          backgroundColor: { colorArray },
        } = section
        return (
          <PressLogoList
            asSeenText={asSeenText}
            key={`contentful-sections-press-logo-list-${id}`}
            links={linksCollection.items.map((link) => ({
              ...link,
              quote: link.quote,
              image: {
                ...link.image,
                alt: link.publicationName,
                src: link.image.url,
              },
            }))}
            backgroundColor={colorArray}
          />
        )
      }

      if (isContentfulFaq(section)) {
        return (
          <FAQ
            key={`contentful-sections-faq-${id}`}
            title={section.title}
            questions={section.questionsCollection.items.map((question) => ({
              answer: question.answer,
              question: question.question,
              id: question.sys.id,
            }))}
          />
        )
      }

      if (isContentfulInstagramSection(section)) {
        const { handle, title, instasubtitle, postsCollection } = section

        // // console.log('[contentful-sections] instagram section', { handle })

        return (
          <InstagramGallery
            key={section.sys.id}
            handle={handle}
            title={title}
            subtitle={instasubtitle}
            posts={postsCollection?.items}
          />
        )
      }

      if (isContentfulSplitHero(section)) {
        if (!section.image) return

        return (
          <SplitHero
            contentColor={section.contentColor?.colorArray}
            minHeight={section.minHeight}
            maxHeight={section.maxHeight}
            imageIsOnRight={section.imageIsOnRight}
            contentBackgroundColor={section.contentBackgroundColor?.colorArray}
            title={section.title}
            titleTag={section.titleTag}
            src={section.image?.url}
            subtitle={section?.herosubtitle}
            key={section.sys.id}
          />
        )
      }

      if (isContentfulIconSection(section)) {
        return (
          <IconFeatures
            key={section.sys.id}
            {...section}
            features={section.featuresCollection?.items.map((feature) => ({
              id: feature.sys.id,
              description: feature.description,
              icon: feature.icon,
              title: feature.title,
              titleColor: feature.titleColor,
            }))}
          />
        )
      }

      if (isContentfulRoundedImage(section)) {
        const { alt, image } = section
        if (!image) return null

        return <RoundedImage alt={alt} key={section.sys.id} image={image} />
      }

      if (isContentfulRichPageContent(section)) {
        return (
          <RichPageContent
            textAlign={section.align}
            json={section?.content?.json}
            maxWidth={section.maxWidth}
            key={section.sys.id}
          />
        )
      }

      if (isContentfulContactForm(section)) {
        const { title, successMessage, successTitle } = section

        return (
          <ContactForm
            title={title}
            successMessage={successMessage}
            successTitle={successTitle}
            key={section.sys.id}
          />
        )
      }

      if (isContentfulEmbedHTML(section)) {
        return <EmbedHTML html={section.html} key={section.sys.id} />
      }

      if (isContentfulDynamicProductDescription(section)) {
        // Return no jsx element
        return null
      }

      if (isContentfulProductVideoReview(section)) {
        const { title, videoReviewLinks } = section

        // // console.log('[contentful-sections] product video review: ', videoReviewLinks)

        return (
          <VideoReviewCarousel
            videoReviewLinks={videoReviewLinks}
            title={title}
            key={section.sys.id}
          />
        )
      }

      if (isContentfulQuiz(section)) {
        // console.log('[quiz-section]', section)
        return (
          <Quiz
            key={section.sys.id}
            {...section}
            chargifyDiscountCode={section.chargifyDiscountCode}
          />
        )
      }

      if (isContentfulSubscribeSection(section)) {
        return <SubscribeSection {...section} key={section.sys.id} />
      }
      if (isContentfulTwoButtonTextSection(section)) {
        return <TwoButtonTextSection {...section} key={section.sys.id} />
      }
      if (isContentfulContentSection(section)) {
        return <ContentSection {...section} key={section.sys.id} />
      }
      if (isContentfulSMSSubscribeSection(section)) {
        return <SMSSubscribeSection {...section} key={section.sys.id} />
      }
      return null
    })
  }, [sections, isProductPage, sku])
  return <>{renderSections}</>
}

export default React.memo(ContentfulSections)
