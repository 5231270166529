/** @jsxImportSource theme-ui */
import { jsx, Flex, Box, Container } from 'theme-ui'

import React, { useMemo, useState } from 'react'
import CollectionProductCard from './Collection-Product-Card'
import { getPrice } from '../../utils/price'
import dynamic from 'next/dynamic'
import { Styled } from '~/components/styled'
import { useGlobalAppProps } from '~/api/GlobalAppPropsContext'

const LabReportsModal = dynamic(() => import('./Lab-Reports-Modal'), {
  ssr: false,
})

type Props = {
  itemsPerRow_mobile?: number
  itemsPerRow_tablet?: number
  itemsPerRow?: number
  showTitle?: boolean
  showDescription?: boolean
  title?: string
  backgroundColor?: string[]
  imageHeightPercentage?: string[]
  itemMaxHeight?: string[]
  showReviewsCount?: boolean
  products: {
    productHandle: string
    images: {
      url: string
    }[]
  }[]
  isLabReportsSection?: boolean
  labReports?: {
    productHandle?: string
    images?: {
      height: number
      width: number
      url: string
    }[]
  }[]
}

const CollectionProductGird = (props: Props) => {
  const {
    itemsPerRow_mobile = 2,
    itemsPerRow_tablet = 3,
    itemsPerRow = 4,
    showTitle = false,
    showDescription = false,
    // productHandles = empty.array,
    backgroundColor,
    itemMaxHeight,
    imageHeightPercentage,
    showReviewsCount = true,
    products,
    title,
    isLabReportsSection,
    labReports,
  } = props

  // const edges = useAllShopifyProductsByHandle(
  //   useMemo(
  //     () => ({
  //       productHandles: products.map((product) => product.productHandle),
  //     }),
  //     [products]
  //   )
  // ).data

  const { allShopifyProduct: edges, allStampedReviewSummaries: reviews } =
    useGlobalAppProps() || {}

  const [openLabReportProductHandle, setOpenLabReportProductHandle] = useState<
    string | null
  >(null)

  const description = ''

  return (
    <Box sx={{ paddingY: [4, 6], bg: backgroundColor }}>
      <Container sx={{ maxWidth: '1500px' }}>
        {!!showTitle && title && (
          <Styled.h2 sx={{ textAlign: 'center', fontSize: 6 }}>
            {title}
          </Styled.h2>
        )}
        {!!(showDescription && description) && (
          <Styled.p>{description}</Styled.p>
        )}
        <Flex sx={{ justifyContent: 'center', flexWrap: 'wrap', paddingX: 2 }}>
          {products.map(({ images, productHandle }) => {
            const shopifyProduct = edges?.find(
              ({ handle }) => handle === productHandle
            )
            if (!shopifyProduct) return null

            const fluidImages = images.map((image) => ({
              src: image.url,
              alt: productHandle,
            }))

            // const imagesOld = shopifyProduct.images?.map(image => ({
            //   fluid: image?.localFile?.childImageSharp?.fluid,
            // }))
            const { shopifyId, title, priceRange, handle } = shopifyProduct
            const price = priceRange?.minVariantPrice?.amount
            const currencyCode = priceRange?.minVariantPrice?.currencyCode

            if (
              !shopifyId ||
              !title ||
              !price ||
              !images ||
              !currencyCode ||
              !handle
            ) {
              console.error('[collection-product-grid] missing field(s)', {
                shopifyId,
                title,
                price,
                images,
                currencyCode,
                handle,
              })
              return null
            }

            const reviewsSummary = reviews?.find(
              ({ handle }) => handle && handle === shopifyProduct.handle
            )

            return (
              <Box
                sx={{
                  // flexBasis,
                  width: [
                    `${(1 / itemsPerRow_mobile) * 100}%`,
                    `${(1 / itemsPerRow_tablet) * 100}%`,
                    `${(1 / itemsPerRow) * 100}%`,
                  ],
                  paddingX: 2,
                  marginBottom: 4,
                }}
                key={shopifyId}
              >
                <CollectionProductCard
                  maxHeight={itemMaxHeight}
                  title={title}
                  price={getPrice(price, currencyCode)}
                  images={fluidImages}
                  handle={handle}
                  imageHeightPercentage={imageHeightPercentage}
                  showReviewsCount={showReviewsCount}
                  reviewsCount={reviewsSummary?.count}
                  starRating={reviewsSummary?.rating}
                  ctaText={isLabReportsSection ? 'View Lab Reports' : undefined}
                  sizes="(min-width: 1600px) 353px, (min-width: 1220px) calc(19.44vw + 46px), (min-width: 780px) calc(33.33vw - 24px), calc(50vw - 27px)"
                  onClick={
                    isLabReportsSection
                      ? () => {
                          setOpenLabReportProductHandle(productHandle)
                        }
                      : undefined
                  }
                />
              </Box>
            )
          })}
        </Flex>
      </Container>
      {!!openLabReportProductHandle && (
        <LabReportsModal
          labReport={labReports?.find((report) => {
            return report.productHandle === openLabReportProductHandle
          })}
          onClose={() => setOpenLabReportProductHandle(null)}
        />
      )}
    </Box>
  )
}

export default React.memo(CollectionProductGird)
