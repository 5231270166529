/** @jsxImportSource theme-ui */

import React, { ComponentPropsWithoutRef, ComponentType } from 'react'
import { Styled } from './styled'

type Props = {
  str: string
  as?: ComponentType
} & Omit<ComponentPropsWithoutRef<typeof Styled.p>, 'children'>

const StringToParagraph = ({ str, as, ...props }: Props) => {
  const Component = as ?? Styled.p
  if (str && !str?.split) {
    if (process.env.NODE_ENV === 'development')
      throw new Error(
        '[StringToParagraph] str is not a string' + JSON.stringify(str, null, 2)
      )

    return null
  }
  return (
    <React.Fragment>
      {str?.split('\n').map((chunk, index) => (
        <Component key={`${index}`} {...props}>
          {chunk}
        </Component>
      ))}
    </React.Fragment>
  )
}

export default StringToParagraph
