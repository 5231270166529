import { useEffect, useRef, useState } from 'react';

interface LazyVideoProps {
  src: string;
  [key: string]: any;
}

const LazyVideo = ({ src, ...props }: LazyVideoProps) => {
  const videoRef = useRef(null);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry && entry.isIntersecting) {
          setIsInView(true);
          if (videoRef.current) {
            observer.unobserve(videoRef.current); // Stop observing once loaded
          }
        }
      },
      { threshold: 0.25 } // Adjust as needed
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <video
      ref={videoRef}
      controls
      preload="metadata"
      {...props}
    >
      {isInView && (
        <source src={src} type="video/mp4" />
      )}
      Your browser does not support the video tag.
    </video>
  );
};

export default LazyVideo;
