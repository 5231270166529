/** @jsxImportSource theme-ui */
import { Document } from '@contentful/rich-text-types'
import React from 'react'
import { MdCancel as Close } from 'react-icons/md'
import Modal from 'react-modal'
import { Box, Container, Flex, useThemeUI } from 'theme-ui'
import { ContentfulRichText } from '~/features/contentful-sections/Contentful-Rich-Text'
import { useLockBodyScroll } from '~/hooks/use-lock-body-scroll'
import { Styled } from './styled'

Modal.setAppElement('#penguin_app')

type Props = {
  isOpen: boolean
  onClose: () => void
  description: {
    json: Document
  } | null
  title: string | null
}

const QuizHelpModal = (props: Props) => {
  const { isOpen, onClose, description, title } = props
  const {
    theme: { rawColors, space, colors },
  } = useThemeUI()
  useLockBodyScroll({ lock: isOpen })
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: {
          position: `fixed`,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: `${rawColors?.secondary}70`,
          zIndex: 100,
        },
        content: {
          position: `absolute`,
          border: `none`,
          background: `none`,
          padding: 0,
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          WebkitOverflowScrolling: `touch`,
        },
      }}
      contentLabel="Modal"
    >
      <Flex
        onClick={onClose}
        css={{
          position: `relative`,
          height: `100vh`,
        }}
      >
        <Flex
          sx={{
            alignItems: `center`,
            justifyItems: `center`,
            margin: `auto`,
            width: `100%`,
            minHeight: ['100%', 'none'],
          }}
        >
          <Container sx={{ position: 'relative', width: ['90%', '60%'] }}>
            <Box
              sx={{
                bg: 'background',
                mx: [0, 2],
                px: [2, 4],
                py: 6,
                borderRadius: 3,
              }}
            >
              <Box
                sx={{
                  textAlign: ['center'],
                }}
              >
                <Styled.p sx={{ variant: 'text.preHeader', mb: [3, 4] }}>
                  {title}
                </Styled.p>
              </Box>
              <Box
                sx={{
                  display: ['block'],
                  my: 4,
                  textAlign: ['center'],
                }}
              >
                {description?.json && (
                  <ContentfulRichText json={description?.json} />
                )}
              </Box>
            </Box>
            <Close
              data-testid="modal-close"
              onClick={onClose}
              sx={{
                cursor: `pointer`,
                color: colors?.primary ?? undefined,
                fontSize: `30px`,
                position: `absolute`,
                padding: space?.[4] as number,
                // padding: space?.[2] ?? undefined,
                top: 0,
                right: 0,
              }}
              size={75}
            />
          </Container>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default React.memo(QuizHelpModal)
