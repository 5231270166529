/** @jsxImportSource theme-ui */
import { CSSProperties } from 'react'
import { ShopName } from 'src/constants'
import { Box, Flex, Text } from 'theme-ui'
import { Styled } from '~/components/styled'
import { ContentfulImage } from '~/features/image/contentful-image'

type Props = {
  title?: string
  titleTag?: string
  subtitle?: string
  imageIsOnRight?: boolean
  contentBackgroundColor?: string[]
  contentColor?: string[]
  maxHeight?: string[]
  minHeight?: string[]
  src: string
  imageFocusPosition?:
  | CSSProperties['objectPosition']
  | CSSProperties['objectPosition'][]
}

export function SplitHero(props: Props) {
  const {
    imageIsOnRight = true,
    title,
    titleTag = "h1",
    subtitle,
    src,
    contentBackgroundColor,
    minHeight,
    maxHeight,
    imageFocusPosition = null,
  } = props
  // // console.log('[split-hero] rendering!', { title })

  let TitleTag:any = Styled.h2;
  if (titleTag == "h1") {
    TitleTag = Styled.h1;
  } else if (titleTag == "h2") {
    TitleTag = Styled.h2;
  } else if (titleTag == "h3") {
    TitleTag = Styled.h3;
  } 

  const renderContent = () => {
    return (
      <Box
        sx={{
          display: ['block', 'flex'],
          alignItems: [null, 'center'],
          justifyContent: [null, 'center'],
          textAlign: 'center',
          flex: [null, 2],
          backgroundColor: contentBackgroundColor,
        }}
      >
        <Box
          sx={{ maxWidth: [null, null, '90%', '80%'], px: [2, 3, 4, 5], py: 5 }}
        >
          {title && (
            <TitleTag sx={{ lineHeight: '1.1', fontSize: 6 }}>
              {title}
            </TitleTag>
          )}
          {subtitle && <Text>{subtitle}</Text>}
        </Box>
      </Box>
    )
  }

  const renderImage = () => {
    return (
      <Box
        sx={{
          flex: [null, 2, 3],
          minHeight: minHeight ?? ['350px', '350px', '500px', 'none'],
          maxHeight: minHeight ?? 'none',
          position: 'relative',
        }}
      >
        <ContentfulImage
          src={src}
          fill
          style={{ objectFit: 'cover' }}
          alt={`${ShopName} | ${title}`}
          sizes="(max-width: 800px) 100vw, 60vw"
          sx={{
            objectPosition: imageFocusPosition,
          }}
        />
      </Box>
    )
  }

  return (
    <Box>
      <Flex
        sx={{
          flexDirection: [
            'column-reverse',
            imageIsOnRight ? 'row' : 'row-reverse',
          ],
        }}
      >
        {renderContent()}
        {renderImage()}
      </Flex>
    </Box>
  )
}
