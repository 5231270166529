/** @jsxImportSource theme-ui */
import { Container } from 'theme-ui'
import { ShopName } from '~/constants'
import { ContentfulImage } from '~/features/image/contentful-image'

type Props = {
  image: {
    url: string
    height: number
    width: number
  }
  alt?: string
}

export function RoundedImage(props: Props) {
  return (
    <Container sx={{ p: 3 }}>
      <ContentfulImage
        {...props.image}
        alt={props.alt || ShopName}
        src={props.image.url}
        sx={{
          marginX: 3,
          borderRadius: (theme) => [3, theme.radii[3] * 1.5],
          height: 'auto',
        }}
        style={{ margin: 0 }}
      />
    </Container>
  )
}
