/** @jsxImportSource theme-ui */
import { jsx, Box, Flex } from 'theme-ui'
import { ContentfulRichText } from '../Contentful-Rich-Text'
import { memo } from 'react'
import { SlideDown } from 'react-slidedown'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { useTheme } from 'src/hooks/use-theme'
import { Styled } from '~/components/styled'

export type ContentfulFaq = {
  title: string
  questions: {
    question: string
    answer: {
      json: Document
    }
    id: string
  }[]
}

type Props = {
  isOpen: boolean
  onClick: (isOpen: boolean) => void
  index: number
} & Pick<ContentfulFaq['questions'][0], 'answer' | 'question'>

function Q({ question, answer, isOpen, onClick, index }: Props) {
  const { colors } = useTheme()
  return (
    <Box
      sx={{
        borderWidth: 2,
        borderBottomStyle: 'solid',
        overflow: 'hidden',
        borderColor: 'border',
        // bg: 'background',
        borderTopStyle: index === 0 ? 'solid' : undefined,
      }}
    >
      <button
        onClick={() => onClick(isOpen)}
        sx={{
          variant: 'styles.button',
          padding: 4,
          display: 'flex',
          width: '100%',
          textAlign: 'left',
          alignItems: 'center',
        }}
        aria-label="Expand Question"
      >
        <Styled.h2
          sx={{
            margin: 0,
            flex: 1,
            display: 'block',
            color: 'accent',
            fontWeight: '600',
            fontSize: '1rem',
            lineHeight: '1.5rem',
          }}
        >
          {question}
        </Styled.h2>
        <Flex
          sx={{
            transform: !isOpen ? undefined : 'scaleY(-1)',
            transition: 'transform 0.25s ease',
            justifyContent: 'center',
          }}
        >
          <MdKeyboardArrowDown size={30} color={colors.border} />
        </Flex>
      </button>
      <SlideDown style={{ transitionDuration: '.250s' }}>
        {isOpen ? (
          <Box
            sx={{
              // display: isOpen ? undefined : 'none',
              padding: 3,
              // bg: 'muted',
            }}
          >
            <ContentfulRichText json={answer.json} />
          </Box>
        ) : null}
      </SlideDown>
    </Box>
  )
}

export const Question = memo(Q)
