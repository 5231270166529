import { useCallback, useState } from 'react'
import { useAsyncCallback } from 'react-async-hook'

export const useSmsSubscribe = () => {
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [ready, setReady] = useState(false)
  const { execute, loading, error } = useAsyncCallback(async(email: string, firstName: string, lastName: string, phone: string) =>
    await fetch(`/api/shopify/customer/sms-subscribe`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          email, firstName, lastName, phone
        }),
      }).then(res=>res.json())
  )

  const onSubmit = useCallback(
    async e => {
      e.preventDefault()
      // // console.log('[use-email-auth] submitted!', { email })
      if (!email) return
      if (!firstName) return
      if (!lastName) return
      if (!phone) return

      const response = await execute(email, firstName, lastName, phone)
      setReady(response.success)
    },
    [execute, email, firstName, lastName, phone]
  )

  return {
    onSubmit,
    email,
    firstName,
    lastName,
    phone,
    loading,
    networkError: error,
    setEmail,
    setFirstName,
    setLastName,
    setPhone,
    ready,
    error,
  }
}
