/** @jsxImportSource theme-ui */
import { jsx, Container, Box } from 'theme-ui'
import { ContentfulFaq, Question } from './Question'
import { Fragment, useState } from 'react'
import 'react-slidedown/lib/slidedown.css'
import { Styled } from '~/components/styled'

type Props = ContentfulFaq

export default function FAQ({ questions, title }: Props) {
  const [openQuestion, setOpenQuestion] = useState<string | null>(null)
  return (
    <Box sx={{ marginY: 5, paddingBottom: 5 }}>
      <Container sx={{ maxWidth: 10, paddingX: 2 }}>
        <Styled.h2 sx={{ textAlign: 'center', fontSize: 6 }}>{title}</Styled.h2>
        <Box
          sx={{
            // borderRadius: 3,
            overflow: 'hidden',
            // boxShadow: 'header',
          }}
        >
          {questions.map((question, i) => (
            <Fragment key={question.id}>
              <Question
                index={i}
                isOpen={question.id === openQuestion}
                onClick={() =>
                  setOpenQuestion((state) =>
                    state === question.id ? null : question.id
                  )
                }
                {...question}
              />
              {/* {i !== questions.length - 1 && (
                <Box sx={{ height: '3px', bg: 'text' }} marginY={0} />
              )} */}
            </Fragment>
          ))}
        </Box>
      </Container>
    </Box>
  )
}
