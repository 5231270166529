/** @jsxImportSource theme-ui */
import { BLOCKS, Document } from '@contentful/rich-text-types'
import Link from 'next/link'
import { Box, Button, Flex, Text } from 'theme-ui'
import { ContentfulImage } from '../image/contentful-image'
import { ContentfulRichText } from './Contentful-Rich-Text'

type Props = {
  title?: string | undefined
  description?: Document | undefined
  leftText?: boolean | undefined
  firstButtonImage?: {
    url: string
    height: number
    width: number
  }
  firstButtonText?: string | undefined
  firstButtonUrl?: string | undefined
  secondButtonImage?: {
    url: string
    height: number
    width: number
  }
  secondButtonText?: string | undefined
  secondButtonUrl?: string | undefined
}

export function TwoButtonTextSection(props: Props) {
  const {
    title,
    description,
    leftText,
    firstButtonImage,
    firstButtonText,
    firstButtonUrl,
    secondButtonImage,
    secondButtonText,
    secondButtonUrl,
  } = props
  return (
    <div
      id={title?.toLowerCase().replace(/ /g, '-')}
      sx={{
        scrollBehavior: 'smooth',
      }}
    >
      <Flex
        sx={{
          paddingY: [2, 5],
          paddingX: [3, 10],
          columnGap: '100px',
          rowGap: '20px',
          display: ['flex'],
          flexDirection: ['column', 'column', leftText ? 'row' : 'row-reverse'],
        }}
      >
        <Flex
          sx={{
            flex: 1,
            display: ['flex'],
            flexDirection: ['column'],
          }}
        >
          {title && (
            <Text
              sx={{
                textAlign: ['center', 'left'],
                textTransform: 'uppercase',
                fontWeight: 'bold',
                fontSize: [5, 6],
              }}
            >
              {title}
            </Text>
          )}
          <Box
            sx={{
              textAlign: ['center', 'left'],
            }}
          >
            {description?.json && (
              <ContentfulRichText
                json={description.json}
                renderNode={{
                  [BLOCKS.PARAGRAPH]: (_, c) => (
                    <Text sx={{ mb: 0, display: 'block' }}>{c}</Text>
                  ),
                }}
              />
            )}
          </Box>
        </Flex>
        <Flex
          sx={{
            flex: 1,
            display: ['flex'],
            flexDirection: ['column', 'row'],
            gap: 20,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {(firstButtonText || firstButtonImage) && (
            <Link href={firstButtonUrl || '#'}>
              {firstButtonImage ? (
                <ContentfulImage
                  {...firstButtonImage}
                  src={firstButtonImage?.url}
                  width={200}
                  height={50}
                  alt={firstButtonText || 'Logo'}
                  sx={{
                    height: 'auto',
                    border: '1px solid black',
                    margin: 0,
                    background: 'transparent',
                    transition: 'background 0.25s ease',
                    willChange: 'background',
                    '&:hover, &:active': {
                      background: '#00000011',
                    },
                  }}
                />
              ) : (
                <Button
                  variant="primary"
                  sx={{
                    width: 250,
                    borderRadius: 0,
                  }}
                >
                  {firstButtonText}
                </Button>
              )}
            </Link>
          )}

          {(secondButtonText || secondButtonImage) && (
            <Link href={secondButtonUrl || '#'}>
              {secondButtonImage ? (
                <ContentfulImage
                  {...secondButtonImage}
                  width={200}
                  height={50}
                  src={secondButtonImage?.url}
                  alt={secondButtonText || 'Logo'}
                  sx={{
                    height: 'auto',
                    border: '1px solid black',
                    margin: 0,
                    background: 'transparent',
                    transition: 'background 0.25s ease',
                    willChange: 'background',
                    '&:hover, &:active': {
                      background: '#00000011',
                    },
                  }}
                />
              ) : (
                <Button
                  variant="primary"
                  sx={{
                    width: 250,
                    borderRadius: 0,
                  }}
                >
                  {secondButtonText}
                </Button>
              )}
            </Link>
          )}
        </Flex>
      </Flex>
    </div>
  )
}
