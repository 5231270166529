/** @jsxImportSource theme-ui */
import { BLOCKS, Document } from '@contentful/rich-text-types'
import { ComponentPropsWithoutRef } from 'react'
import { Box, Container, Text } from 'theme-ui'
import StringToParagraph from '~/components/StringToP'
import { Styled } from '~/components/styled'
import { ContentfulRichText } from '../Contentful-Rich-Text'

type Props = {
  title: string
  titleTag?: string
  preTitle?: string
  textAlign?: (string | null)[]
  style?: ComponentPropsWithoutRef<typeof Box>['sx']
  titleStyle?: ComponentPropsWithoutRef<typeof Styled.h2>['sx']

  subtitle?: {
    json?: Document
  }
  fontWeight?: string[]
  headlineFontSize?: string[]
  backgroundColor?: {
    colorArray?: string[]
  }
}

const TitleSection = ({
  title,
  titleTag = "h2",
  preTitle,
  textAlign = ['center'],
  style,
  titleStyle,
  headlineFontSize,
  fontWeight,
  subtitle,
  backgroundColor
}: Props) => {
  let TitleTag:any = Styled.h2;
  if (titleTag == "h1") {
    TitleTag = Styled.h1;
  } else if (titleTag == "h2") {
    TitleTag = Styled.h2;
  } else if (titleTag == "h3") {
    TitleTag = Styled.h3;
  } 
  return (
    <Box sx={{ textAlign, ...style, backgroundColor: backgroundColor }}>
      <Container sx={{ maxWidth: [null, 9, 10], px: 3 }}>
        {!!preTitle?.trim() && (
          <Styled.p
            sx={{
              variant: 'text.preHeader',
              // fontWeight: fontWeight ?? ['500'],
              fontWeight: '500',
              mb: 3,
            }}
          >
            {preTitle}
          </Styled.p>
        )}
        <StringToParagraph
          sx={{
            // fontWeight: 'normal',
            // marginBottom: 0,
            ...titleStyle,
            fontSize: headlineFontSize,
            // fontSize: [5, 6],
            fontWeight: fontWeight ?? ['500'],
          }}
          str={title}
          as={TitleTag}
        />
        {subtitle?.json && (
          <Box sx={{ mt: 3 }}>
            <ContentfulRichText
              json={subtitle.json}
              renderNode={{
                [BLOCKS.PARAGRAPH]: (_, c) => (
                  <Text sx={{ mb: 0, display: 'block', textAlign }}>{c}</Text>
                ),
              }}
            />
          </Box>
        )}
      </Container>
    </Box>
  )
}

export default TitleSection
