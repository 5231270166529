import btoa from 'btoa';
import {
    StampedQuestionSchema,
    StampedReviewSchema,
} from '~/features/product/Product-Reviews/fragments';
import { queryShopifyStorefront } from './shopify-storefront-query';

type StoreOptions = {
  productIds?: { productId: string; handle: string }[]
}

const serverEndpoint = process.env.NEXT_PUBLIC_SERVER_ENDPOINT

type ReviewOptions = {
  storeUrl: string
  apiKey: string
  productId?: string
}

const getShopifyProducts = async (): Promise<
  { id: string; handle: string }[] | undefined
> => {
  const response = await queryShopifyStorefront(
    `query {
        products(first:100){
          edges {
            node {
              handle
              id
            }
          }
        }
      }
    `
  );
  const products = response.data.products.edges.map(
    (edge: { node: { handle: string; id: string } }) => edge.node
  );
  return products;
}

const storeUrl = `${process.env.NEXT_PUBLIC_SHOP_NAME}.myshopify.com`

const getStampedReviewsSummaries = async ({
  productIds,
}: StoreOptions): Promise<
  | {
      rating: number
      productId: string
      countQuestions: number
      count: number
      c: boolean
      breakdown: {
        rating1: number
        rating2: number
        rating3: number
        rating4: number
        rating5: number
      }
    }[]
  | undefined
> => {
  try {
    const url = 'https://stamped.io/api/widget/badges?isIncludeBreakdown=true&isincludehtml=false';
    const method = 'POST';
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = JSON.stringify({
      storeUrl,
      apiKey,
      productIds,
    });
    // console.log(`curl -X ${method} -H "Content-Type: application/json" -d '${body}' ${url}`);
    const response = await fetch(url, {
      method,
      headers,
      body,
    });
    
    return response.json()
  } catch (e) {
    console.error('shopify products from server error', e)
  }
}

const getStampedReviewSummariesForAllProducts = async () => {
  const productIds = (await getShopifyProducts())?.map((product) => ({
    productId: product.id,
    handle: product.handle,
  }))
  if (Array.isArray(productIds)) {
    return await getStampedReviewsSummaries({
      productIds,
    }).then(
      (summaries) =>
        summaries?.map((summary) => ({
          ...summary,
          handle:
            productIds.find(
              (product) =>
                product.productId.toString() === summary.productId.toString()
            )?.handle ?? null,
        }))
    )
  } else {
    return []
  }
}

// https://developers.stamped.io/#da27f990-cabd-4882-864a-7b8f9dd55832
const getStampedReviewsByProductId__client = async (
  productId: string,
  {
    hasPhotos,
    limit,
    sortBy,
    page,
  }: {
    hasPhotos?: boolean
    limit?: number
    page?: number
    sortBy?:
      | 'featured'
      | 'highest-rating'
      | 'lowest-rating'
      | 'photos'
      | 'most-votes'
  } = {}
): Promise<
  | {
      page: number
      data: StampedReviewSchema[]
    }
  | undefined
> => {
  try {
    const response = await fetch(
      `https://stamped.io/api/widget/reviews?productId=${productId}&productType&email&isWithPhotos${
        hasPhotos ? '=true' : ''
      }&minRating&take${limit != null ? '=' + limit : ''}&page${
        page != null ? '=' + page : ''
      }&dateFrom&dateTo&sortReviews${
        sortBy ? '=' + sortBy : ''
      }&tags&storeUrl=${storeUrl}&apiKey=${apiKey}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    return response.json()
  } catch (e) {
    console.error('shopify products from server error', e)
  }
}

const apiKey = process.env.NEXT_PUBLIC_STAMPED_KEY
const storeHash = process.env.STAMPED_HASH
const secretKey = process.env.STAMPED_SECRET_KEY

const getStampedAllQuestions = async (): Promise<
  { results: StampedQuestionSchema[] } | undefined
> => {
  try {
    const basic = btoa(`${apiKey}:${secretKey}`)
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    headers.append('Authorization', `Basic ${basic}`)

    const response = await fetch(
      `https://stamped.io/api/v2/${storeHash}/dashboard/questions/?search=&state=&dateTo&dateFrom`,
      {
        method: 'GET',
        headers,
      }
    )
    return response.json()
  } catch (e) {
    console.error('stamped questions fetch error', e)
  }
}

export {
    getShopifyProducts, getStampedAllQuestions,
    getStampedReviewSummariesForAllProducts, getStampedReviewsByProductId__client as getStampedReviewsByProductId__client, getStampedReviewsSummaries
};

