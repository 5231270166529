/** @jsxImportSource theme-ui */
import { BLOCKS, Document } from '@contentful/rich-text-types'
import { Flex, Text } from 'theme-ui'
import { Styled } from '~/components/styled'
import { ShopName } from '~/constants'
import { ContentfulImage } from '../image/contentful-image'
import { ContentfulRichText } from './Contentful-Rich-Text'
type Props = {
  title?: string | undefined
  description?: Document | undefined
  horizontal?: boolean | undefined
  itemsCollection?: {
    items?: {
      title?: string | undefined
      subTitle?: string | undefined
      description?: Document | undefined
      image: {
        url: string
        height: number
        width: number
      }
    }[]
  }
  showContentBorder?: boolean
  backgroundColor?: { colorArray: string[] }
  textColor?: { colorArray: string[] }
  borderColor?: { colorArray: string[] }
}

export function ContentSection(props: Props) {
  const {
    title,
    description,
    horizontal,
    itemsCollection,
    showContentBorder,
    backgroundColor,
    textColor,
    borderColor,
  } = props
  return (
    <div
      sx={{
        background: backgroundColor?.colorArray || 'muted',
        color: textColor?.colorArray || 'text',
        paddingY: 2,
      }}
    >
      <Flex
        sx={{
          display: 'flex',
          flexDirection: [
            'column',
            'column',
            'column',
            horizontal ? 'row' : 'column',
          ],
          alignItems: 'center',
          justifyContent: 'center',
          background: 'background',
          paddingY: 8,
          paddingX: 4,
          gap: 4,
        }}
      >
        <Flex
          sx={{
            flex: 1,
            paddingY: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {title && (
            <Text
              sx={{
                fontSize: [5, 6, 7, 8],
                color: ['text'],
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              {title}
            </Text>
          )}
          {description?.json && (
            <ContentfulRichText
              renderNode={{
                [BLOCKS.PARAGRAPH]: (_, c) => (
                  <Text sx={{ mb: 0, display: 'block' }}>{c}</Text>
                ),
              }}
              json={description?.json}
            />
          )}
        </Flex>

        <Flex
          sx={{
            flex: 1.5,
            paddingY: 2,
            display: 'grid',
            gridTemplateColumns: [
              'repeat(1, 1fr)',
              'repeat(2, 1fr)',
              'repeat(3, 1fr)',
            ],
            columnGap: 4,
            rowGap: 4,
          }}
        >
          {itemsCollection?.items?.map((item, index) => {
            return (
              <div
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  gap: 2,
                  borderWidth: showContentBorder ? 5 : 0,
                  borderStyle: 'solid',
                  borderColor: showContentBorder
                    ? borderColor?.colorArray
                    : 'none',
                  m: showContentBorder ? 3 : 0,
                  p: showContentBorder ? 5 : 0,
                  justifyContent: showContentBorder ? 'center' : 'flex-start',
                  borderRadius: '20px',
                }}
              >
                {item?.image && (
                  <ContentfulImage
                    {...item.image}
                    alt={item?.title || ShopName}
                    src={item?.image?.url}
                    width={150}
                    height={150}
                    sx={{
                      width: '150px',
                      height: '150px',
                      marginX: 3,
                      borderRadius: (theme) => [3, theme.radii[3] * 1.5],
                      height: 'auto',
                    }}
                    style={{ margin: 0 }}
                  />
                )}
                {item?.title && (
                  <Styled.h2 sx={{ fontSize: [3, 4, 5, 6], p: 0, m: 0 }}>
                    {item?.title}
                  </Styled.h2>
                )}
                {item?.subTitle && (
                  <Text sx={{ fontSize: [2, 3, 4, 5], p: 0, m: 0 }}>
                    {item?.subTitle}
                  </Text>
                )}
                {item?.description?.json && (
                  <ContentfulRichText
                    renderNode={{
                      [BLOCKS.PARAGRAPH]: (_, c) => (
                        <Text sx={{ mb: 0, display: 'block' }}>{c}</Text>
                      ),
                    }}
                    json={item?.description?.json}
                  />
                )}
              </div>
            )
          })}
        </Flex>
      </Flex>
    </div>
  )
}
