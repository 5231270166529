/** @jsxImportSource theme-ui */
import { Flex, Box, Container } from 'theme-ui'

import React from 'react'
import { Styled } from '~/components/styled'
import ImageCard from './image-Card'


type Props = {
  title?: string
  items: {
    text: string
    url: string
    image: {
      url: string
      height: number
      width: number
    }
  }[]
}

const ImageCardGrid = (props: Props) => {
  const {
    title,
    items
  } = props

  return (
    <Box sx={{ paddingY: [4, 6] }}>
      <Container sx={{ maxWidth: '1500px' }}>
        {title && (
          <Styled.h2 sx={{ textAlign: 'center', fontSize: 6 }}>
            {title}
          </Styled.h2>
        )}
        <Flex sx={{ justifyContent: 'center', flexWrap: 'wrap', paddingX: 2 }}>
          {items.map((item, index) => {
            return (
              <Box
                sx={{
                  // flexBasis,
                  width: [
                    `${(1 / 2) * 100}%`,
                    `${(1 / 3) * 100}%`,
                    `${(1 / 4) * 100}%`,
                  ],
                  paddingX: 2,
                  marginBottom: 4,
                }}
                key={index}
              >
                <ImageCard
                  title={item?.text}
                  url={item?.url}
                  image={item?.image}
                />
              </Box>
            )
          })}
        </Flex>
      </Container>
    </Box>
  )
}

export default React.memo(ImageCardGrid)
