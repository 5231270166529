/** @jsxImportSource theme-ui */
import { jsx, Container } from 'theme-ui'
import { Document } from '@contentful/rich-text-types'
import React from 'react'
import { ContentfulRichText } from './Contentful-Rich-Text'

type Props = {
  json?: Document
  textAlign?: string[]
  maxWidth?: string[]
}

export function RichPageContent(props: Props) {
  const { json, textAlign, maxWidth = ['900px'] } = props

  if (!json) return null

  return (
    <Container sx={{ px: 3, my: [6, 7], textAlign, maxWidth }}>
      <ContentfulRichText json={json} />
    </Container>
  )
}
