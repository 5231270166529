import { useRouter } from 'next/router'
import React, { useEffect, useRef } from 'react'

type Props = {
  html?: string
}

export default function EmbedDangerousHTML(props: Props) {
  const { html } = props

  const ref = useRef<HTMLDivElement>(null)

  const asPath = useRouter().asPath
  useEffect(() => {
    if (!html || !ref.current) return

    if (html.includes('gatsby')) {
      console.error(
        'gatsby in html! you likely have to fix this element:',
        asPath,
        html
      )
      return
    }

    if (html.includes('innerHTML')) {
      console.error(
        'innerHTML in html! you likely have to fix this element:',
        asPath,
        html
      )

      return
    }

    if (html.includes('<script')) {
      console.error(
        'script in html! you likely have to fix this element:',
        asPath,
        html
      )

      return
    }

    try {
      const slotHtml = document.createRange().createContextualFragment(html) // Create a 'tiny' document and parse the html string

      ref.current.innerHTML = '' // Clear the container
      ref.current.appendChild(slotHtml) // Append the new content
    } catch {}
  }, [html, asPath])

  if (!html) return null

  // lazy load scripts and such
  if (
    html.includes('script') ||
    html.includes('embed') ||
    html.includes('iframe')
  ) {
    return <div style={{ maxWidth: '100%', overflow: 'hidden' }} ref={ref} />
  }

  return (
    <div
      style={{ maxWidth: '100%', overflow: 'hidden' }}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
  // return <div dangerouslySetInnerHTML={{ __html: html }} ref={ref} />
}
