/** @jsxImportSource theme-ui */
import { Flex, Text } from 'theme-ui'

import {
  ComponentPropsWithoutRef,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { Box, Container } from 'theme-ui'
import { ShopName } from '../../constants'
import { MdChevronLeft, MdChevronRight, MdStar } from 'react-icons/md'
import { useTheme } from '../../hooks/use-theme'
import { useEmblaCarousel } from 'embla-carousel/react'
import {
  ContentfulImage,
  ContentfulImage as Image,
} from '../image/contentful-image'

type Props = {
  links: {
    url: string
    publicationName: string
    image: ComponentPropsWithoutRef<typeof Image>
    quote: string
  }[]
  logoWidth?: number | number[]
  logoMaxHeight?: number | number[]
  asSeenText?: string
  backgroundColor: string[]
  showReviewsCount?: boolean
}

const defaultProps = {
  // logoWidth: [75, 150],
  logoWidth: [120],
  logoMaxHeight: ['1px'],
}

export const PressLogoList = (props: Props) => {
  const {
    links,
    logoWidth = defaultProps.logoWidth,
    asSeenText: reviewsCountText = '200+ 5-star reviews',
    backgroundColor,
    showReviewsCount = true,
  } = props

  const theme = useTheme()
  const { colors } = theme

  const [EmblaCarouselReact, embla] = useEmblaCarousel({
    loop: true,
    draggable: true,
  })

  const [currentIndex, setCurrentIndex] = useState<number>(0)

  useEffect(() => {
    embla?.on('select', () => {
      setCurrentIndex(embla.selectedScrollSnap())
    })
  }, [embla])

  const renderList = useCallback(
    ({ isCarousel }: { isCarousel: boolean } = { isCarousel: false }) => {
      return [...links].map((link, i) => {
        const quote =
          link.quote.startsWith('"') && link.quote.endsWith('"')
            ? link.quote
            : `"${link.quote}"`

        const renderReviewsCount = () => {
          if (!showReviewsCount) return null
          return (
            <Flex
              sx={{
                textAlign: 'center',
                flexDirection: 'column',
                p: 5,
                ...(isCarousel
                  ? {
                      flex: '0 0 100%',
                      justifyContent: 'center',
                    }
                  : {
                      display: ['none', null, 'flex'],
                    }),
              }}
            >
              <Flex sx={{ mb: 4, justifyContent: 'center' }}>
                <MdStar color={colors.text} size={25} sx={{ mx: 2 }} />
                <MdStar color={colors.text} size={25} sx={{ mx: 2 }} />
                <MdStar color={colors.text} size={25} sx={{ mx: 2 }} />
                <MdStar color={colors.text} size={25} sx={{ mx: 2 }} />
                <MdStar color={colors.text} size={25} sx={{ mx: 2 }} />
              </Flex>
              <Box>
                <Text sx={{ fontWeight: 'bold' }}>{reviewsCountText}</Text>
              </Box>
            </Flex>
          )
        }

        return (
          <Fragment key={link.url}>
            {i === 2 && renderReviewsCount()}
            <a
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                textDecoration: 'none',
                display: 'flex',
                position: 'relative',
                ...(isCarousel
                  ? {
                      flex: '0 0 100%',
                      justifyContent: 'center',
                    }
                  : {
                      flex: [null, 1],
                    }),
              }}
            >
              <Flex
                sx={{
                  py: 5,
                  px: [3, 5],
                  alignItems: 'center',
                  flexDirection: 'column',
                  maxWidth: '300px',
                  justifyContent: isCarousel ? 'center' : 'space-between',
                  flex: 1,
                }}
              >
                <Text
                  sx={{
                    fontSize: [3, 2],
                    color: 'text',
                    textAlign: 'center',
                    mb: 4,
                    display: 'block',
                  }}
                >
                  {quote}
                </Text>
                <Box>
                  <ContentfulImage
                    {...link.image}
                    alt={`${ShopName} featured in ${link.publicationName}`}
                    width={link.image.width}
                    height={link.image.height}
                    sx={{
                      // width: '75px',
                      // maxHeight: '100%',
                      // height: '100%',
                      width: logoWidth,
                      objectFit: 'contain',
                      height: 'auto',
                      mb: 0,
                    }}
                    loading="lazy"
                  />
                </Box>
              </Flex>
            </a>
          </Fragment>
        )
      })
    },
    [colors.text, links, logoWidth, reviewsCountText, showReviewsCount]
  )

  const renderMobileOnlyCarousel = () => {
    return (
      <Box sx={{ display: ['block', 'none'] }}>
        <Flex
          sx={{
            alignItems: 'center',
            bg: 'background',
            mx: ['1rem', 2],
            borderRadius: 3,
            mt: -5,
            // zIndex: 100,
            position: 'relative',
            // transform: 'translateY(-40px)',
            borderWidth: 1,
            // borderStyle: 'solid',
            borderColor: 'text',
            boxShadow: ['#4a494940 0px 0px 5px 1px', 'none'],
          }}
        >
          <Box sx={{ cursor: 'pointer' }} onClick={() => embla?.scrollPrev()}>
            <MdChevronLeft size={35} color={colors.text} />
          </Box>
          <Box sx={{ flex: 1 }}>
            <EmblaCarouselReact
              sx={{
                // borderWidth: 1,
                // borderStyle: 'solid',
                mx: 1,
                // bg: 'background',
                // borderColor: 'secondary',
                // boxShadow: 'header',
              }}
            >
              <Flex>
                {renderList({
                  isCarousel: true,
                })}
              </Flex>
            </EmblaCarouselReact>
          </Box>
          <Box sx={{ cursor: 'pointer' }} onClick={() => embla?.scrollNext()}>
            <MdChevronRight size={35} color={colors.text} />
          </Box>
        </Flex>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        py: 3,
        background: backgroundColor.map((color) => {
          const isGradient = color.includes('&')
          if (isGradient) {
            const gradient = color
              .split('&')
              .map((c) => c.trim())
              .map((c) => colors[c as keyof typeof colors] ?? c)
              .filter(Boolean)
            return `linear-gradient(${[...gradient].join(', ')})`
          }
          return colors[color as keyof typeof colors] ?? color
        }),
      }}
    >
      <Container sx={{ maxWidth: '1500px' }}>
        {renderMobileOnlyCarousel()}
        <Flex
          sx={{
            flexWrap: 'wrap',
            justifyContent: 'center',
            display: ['none', 'flex'],
            alignItems: 'center',
            // alignItems: 'center',
          }}
        >
          {/* <Box>
            <Styled.h6
              sx={{
                variant: 'text.spacedOut',
                color: 'gray',
                display: ['none', 'inline'],
                pr: 3,
              }}
            >
              {asSeenText}
            </Styled.h6>
          </Box> */}
          {renderList()}
        </Flex>
      </Container>
    </Box>
  )
}
