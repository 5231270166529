export const queryShopify = async <Returns extends any>(
  query: any,
  variables?: {}
) => {
  const headers = new Headers()
  headers.append('X-Shopify-Access-Token', process.env.SHOPIFY_ADMIN_PASSWORD!)
  headers.append('Content-Type', 'application/json')
  const url = `https://${process.env.NEXT_PUBLIC_SHOP_NAME}.myshopify.com/admin/api/${process.env.NEXT_PUBLIC_SHOPIFY_API_VERSION}/graphql.json`

  return fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      query,
      variables,
    }),
  }).then(async (r) => {
    const result = await r.json();
    return result as Promise<{ data: Returns; __cached?: undefined }>
  })
}
