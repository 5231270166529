/** @jsxImportSource theme-ui */
import { Box, Flex } from 'theme-ui'
import { ComponentProps } from 'react'
import ProductTile from './Product-Tile'

type Props = {
  tiles: Omit<ComponentProps<typeof ProductTile>, 'index'>[]
}
export default function ProductTileSection({ tiles }: Props) {
  return (
    <Box>
      <Flex sx={{ display: ['block', 'flex'], flexWrap: 'wrap' }}>
        {tiles.map((tile, i: number) =>
          tile.src ? <ProductTile index={i} key={tile.title} {...tile} /> : null
        )}
      </Flex>
    </Box>
  )
}
