/** @jsxImportSource theme-ui */

import PhoneInput from 'react-phone-number-input/input'
import 'react-phone-number-input/style.css'
import { Box, Button, Card, Flex, Input } from 'theme-ui'
import { Styled } from '~/components/styled'
import { useSmsSubscribe } from '~/hooks/use-sms-subscribe'
import CustomPhoneInput from '../../components/CustomPhoneInput'
import { ContentfulImage } from '../image/contentful-image'

type Props = {
  title?: string | undefined
  background?: {
    url: string
    height: number
    width: number
  }
  image?: {
    url: string
    height: number
    width: number
  }
  titleColor?: { colorArray: string[] }
}

export function SMSSubscribeSection(props: Props) {
  const { title, background, image, titleColor } = props

  const {
    onSubmit,
    email,
    firstName,
    lastName,
    phone,
    loading,
    setEmail,
    setFirstName,
    setLastName,
    setPhone,
    ready,
    error,
  } = useSmsSubscribe()

  return (
    <Box sx={{ position: 'relative', height: '90vh' }} id="sms-opt-in">
      <ContentfulImage
        src={background?.url}
        fill
        sx={{
          objectFit: 'cover',
          objectPosition: '100% center',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: ['column', 'row'],
          top: [9],
          left: [0, 5, 10],
          right: [0, 5, 10],
          bottom: 0,
          position: 'absolute',
        }}
      >
        <Box
          sx={{
            flex: [1, 1, 1.3],
            display: ['flex'],
            paddingTop: [null, 7],
            paddingX:[5, 0],
            flexDirection: 'column',
            position: 'relative',
            justifyContent:["center", "flex-start"]
          }}
        >
          <Styled.h1
            sx={{
              textAlign: ['center', 'left'],
              color: titleColor?.colorArray,
              fontSize: [5, 6, 7],
            }}
          >
            {title}
          </Styled.h1>
          {image && (
            <Box
              sx={{
                display: ['none', 'block'],
                position: 'absolute',
                bottom: 0,
                height: (400 * (image?.height || 1)) / (image?.width || 1),
                width: ['100%', 400],
              }}
            >
              <ContentfulImage
                src={image?.url}
                fill
                sx={{ objectFit: 'contain', m: 0 }}
              />
            </Box>
          )}
        </Box>
        <Box sx={{ flex: 1, padding: 5 }}>
          <Box sx={{ px: 3, maxWidth: 500, pb: [6, 7] }}>
            <Card
              sx={{
                px: [3, 5],
                py: [4, 6],
                boxShadow: 'header',
                bg: 'background',
                borderRadius: 3,
              }}
            >
              <Styled.h2
                sx={{
                  fontSize: [4, 5],
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  color: ["text"],
                }}
              >
                Join The Penguin Text Club!
              </Styled.h2>
              <Box>
                <Flex sx={{ display: ['block'] }}>
                  <Box sx={{ marginBottom: 3, flex: [null, 1] }}>
                    <Input
                      type="text"
                      placeholder="First name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      sx={{ borderColor: 'border' }}
                    />
                  </Box>
                  {/* <Box sx={{ marginBottom: 3, flex: [null, 1] }}>
                    <Input
                      type="text"
                      placeholder="Last name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      sx={{ borderColor: 'border' }}
                    />
                  </Box> */}
                  <Box sx={{ marginBottom: 3, flex: [null, 1] }}>
                    <Input
                      type="Email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      sx={{ borderColor: 'border' }}
                    />
                  </Box>
                  <Box sx={{ marginBottom: 3, flex: [null, 1] }}>
                    <PhoneInput
                      value={phone}
                      inputComponent={CustomPhoneInput}
                      onChange={setPhone}
                    />
                  </Box>
                </Flex>
              </Box>
              <Box>
                {ready ? (
                  <Button
                    variant="primary"
                    onClick={onSubmit}
                    sx={{
                      borderRadius: (t) => t.radii[3],
                      width: '100%',
                    }}
                    disabled={true}
                  >
                    Subscribed
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    onClick={onSubmit}
                    sx={{
                      borderRadius: (t) => t.radii[3],
                      width: '100%',
                    }}
                    disabled={loading || ready}
                  >
                    {loading ? 'Loading...' : error ? 'Try again' : 'Submit'}
                  </Button>
                )}
              </Box>
            </Card>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
