import { forwardRef } from 'react'
import { Input } from 'theme-ui'

const phoneInput = (props, ref) => {
  return (
    <Input
      {...props}
      ref={ref}
      type="text"
      placeholder="Phone*"
      sx={{ borderColor: 'border' }}
    />
  )
}
export default forwardRef(phoneInput)
