/** @jsxImportSource theme-ui */

import Link from 'next/link'
import { ComponentPropsWithoutRef, memo } from 'react'
import { Box, Card } from 'theme-ui'

import { Styled } from '~/components/styled'
import { ContentfulImage as Image } from '~/features/image/contentful-image'

type Props = {
  title?: string
  image: ComponentPropsWithoutRef<typeof Image>
  url: string
}

const maxH = ['300px']

const ImageCard = memo(function GridCard(props: Props) {
  const {
    title,
    url,
    image
  } = props

  return (
    <Link
      href={url}
      sx={{
        variant: 'links.default',
        textDecoration: 'none',
        cursor: 'pointer',
      }}
    >
      <Card variant="imageCard">
        <Box
          sx={{
            borderRadius: 3,
            overflow: 'hidden',
            position: 'relative',
            maxHeight: maxH,
            paddingBottom: "100%",
            bg: 'mutedSecondary',
          }}
        >
            <Image
              fill
              sizes="(min-width: 1600px) 353px, (min-width: 1220px) calc(19.44vw + 46px), (min-width: 780px) calc(33.33vw - 24px), calc(50vw - 27px)"
              src={image.url}
              alt={""}
              sx={{
                transition: 'transform .25s .1s ease',
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                transform: 'scale(1)',
                objectFit: 'cover',
              }}
              loading="lazy"
            />
        </Box>
      </Card>
      <Box py={4}>
        <Styled.h3 sx={{ mt: 1, textAlign: 'center', fontSize: [3, 5] }}>
          {title}
        </Styled.h3>
      </Box>
    </Link>
  )
})

export default ImageCard
